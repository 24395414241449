const actions = {
  COMPANY_BEGIN: 'COMPANY_BEGIN',
  COMPANY_SUCCESS: 'COMPANY_SUCCESS',
  COMPANY_ERR: 'COMPANY_ERR',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  CompanyBegin: () => {
    return {
      type: actions.COMPANY_BEGIN,
    };
  },

  CompanySuccess: (data: any) => {
    return {
      type: actions.COMPANY_SUCCESS,
      data,
    };
  },

  CompanyErr: (err: any) => {
    return {
      type: actions.COMPANY_ERR,
      err,
    };
  },
};

export default actions;
