import MenuDataModal from '../class/MenuDataModal';

export const MenuId = {
  question: 2,
  companies: 3,
  home: 1,
};
export const MenuIcon = {
  home: 'home',
  question: 'infocircle',
  companies: 'Building',
};
export const MenuLink = {
  question: '/superadmin/libraries',
  home: '/superadmin',
  companies: '/superadmin/companies',
};

export const MenuData: MenuDataModal[] = [
  {
    id: MenuId.home,
    text: 'Home',
    icon: MenuIcon.home,
    link: MenuLink.home,
  },
  {
    id: MenuId.question,
    text: 'Libraries',
    icon: MenuIcon.question,
    link: MenuLink.question,
  },

  {
    id: MenuId.companies,
    text: 'companies',
    icon: MenuIcon.companies,
    link: MenuLink.companies,
  },
];
