import React from 'react';
import { Button } from 'antd';
import CompanyTable from './CompanyTable';
import { Main } from '../../styled';
const Company = () => {
  return (
    <Main>
      <div className="d-flex justify-content-between">
        <h3>Company</h3>
        <Button href="/superadmin/companies/addNew" type="primary">
          Add New
        </Button>
      </div>
      <CompanyTable />
    </Main>
  );
};

export default Company;
