import React from 'react';
import { Switch } from 'react-router-dom';
// import MobSideNav from './MobSideNav';
// import { useRouteMatch } from 'react-router-dom';
import Liai from './Liai';
import CRM from './CRM';
import TargetProgress from './TargetProgress';
import TeamManagement from './TeamManagement';
import TrainingRoom from './TrainingRoom';
import SettingPage from './SettingPage';
import AddCRM from './CRM/AddCRM';
import CRMHistory from './CRM/CRMHistory';
import TopNavBar from '../../components/TopNavBar';
import SideNav from '../../components/SideNav';
// import ErrorPage from '../pages/ErrorPage';
// import PublicRoute from './PublicRoute';
import PrivateRoute from '../../components/PrivateRoute';
import PublicRoute from '../../components/PublicRoute';
import { MenuLink } from '../../constant/MenuData';
import { useDispatch, useSelector } from 'react-redux';
import { MainContent, MainBody, MainContentDiv } from '../styled';
import { roles } from '../../helper/enum';

function DashboardStruct() {
  const { theme } = useSelector((state: any) => {
    return {
      theme: state.ChangeLayoutMode.theme,
    };
  });
  return (
    <div className={!theme ? 'App-Light-header' : 'App-header'} style={{overflow:"hidden"}}>
      <MainBody>
        <TopNavBar />
        <div className="d-flex">
          <SideNav role="user" menuId={1} />
          <MainContentDiv>
            <MainContent>
              <Switch>
                <PublicRoute Component={Liai} path={MenuLink.AffectiveAI} exact role={roles.employee} />
                <PrivateRoute Component={CRM} path={MenuLink.CRM} exact role={roles.employee} />
                <PrivateRoute Component={AddCRM} path={`${MenuLink.CRM}/add`} exact role={roles.employee} />
                <PrivateRoute Component={CRMHistory} path={`${MenuLink.CRM}/history/:id`} exact role={roles.employee} />
                <PrivateRoute Component={TargetProgress} path={MenuLink.TargetProgress} exact role={roles.employee} />
                <PrivateRoute Component={TeamManagement} path={MenuLink.TeamManagement} exact role={roles.employee} />
                <PrivateRoute Component={TrainingRoom} path={MenuLink.TrainingRoom} exact role={roles.employee} />
                <PrivateRoute Component={SettingPage} path={MenuLink.Setting} exact role={roles.employee} />
              </Switch>
            </MainContent>
          </MainContentDiv>
        </div>
      </MainBody>
    </div>
  );
}

export default DashboardStruct;
