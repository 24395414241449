import { message } from 'antd';
import actions from './actions';
import API from '../../config/API';
import { getApi, postApi, putApi } from '../../helper/ApiRequest';
import CompanyModel from '../../class/companyModal';

const { CompanyBegin, CompanySuccess, CompanyErr } = actions;

const addCompanyDirect = (values: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(CompanyBegin());
      const result: any = await postApi(`${API.Company.register}`, values, dispatch);
      if (result.status === 200) {
        message.success('Company Add Successfull');
        return result.data;
      }
    } catch (err) {
      dispatch(CompanyErr(err));
      return false;
    }
  };
};
const addCompany = (values: CompanyModel) => {
  return async (dispatch: any) => {
    try {
      dispatch(CompanyBegin());
      const result: any = await postApi(`${API.Company.root}`, values, dispatch);
      if (result.status === 200) {
        const response: any = await getApi(`${API.Company.root}`, dispatch);
        if (response.status === 200) {
          dispatch(CompanySuccess(response.data));
          message.success('Company Add Successfull');
          return true;
        }
      }
    } catch (err) {
      dispatch(CompanyErr(err));
      return false;
    }
  };
};
const getCompany = () => {
  return async (dispatch: any) => {
    try {
      dispatch(CompanyBegin());
      const response: any = await getApi(`${API.Company.root}`, dispatch);
      if (response.status === 200) {
        dispatch(CompanySuccess(response.data));
      }
    } catch (err) {
      dispatch(CompanyErr(err));
    }
  };
};
const verifyPaymentClient = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(CompanyBegin());
      const response: any = await putApi(`${API.Company.paymentVerify}`, data, dispatch);
      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      dispatch(CompanyErr(err));
    }
  };
};
export { getCompany, addCompany, addCompanyDirect, verifyPaymentClient };
