import React from 'react';
import { FaHome } from 'react-icons/fa';
import { BsInfoCircleFill, BsThreeDotsVertical } from 'react-icons/bs';
import { GiHumanTarget, GiTeacher } from 'react-icons/gi';
import { RiTeamFill } from 'react-icons/ri';
import { AiFillSetting } from 'react-icons/ai';
import { IoIosArrowForward } from 'react-icons/io';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { FaUsers, FaBars, FaEye } from 'react-icons/fa';
import { BiBuildings } from 'react-icons/bi';

export const AppIcons = (val: string, size = '20px'): any => {
  switch (val.toLowerCase()) {
    case 'home':
      return <FaHome style={{ width: size, height: size }} />;
    case 'infocircle':
      return <BsInfoCircleFill style={{ width: size, height: size }} />;
    case 'userincircle':
      return <GiHumanTarget style={{ width: size, height: size }} />;
    case 'team':
      return <RiTeamFill style={{ width: size, height: size }} />;
    case 'traning':
      return <GiTeacher style={{ width: size, height: size }} />;
    case 'setting':
      return <AiFillSetting style={{ width: size, height: size }} />;
    case 'arrowforward':
      return <IoIosArrowForward style={{ width: size, height: size }} />;
    case 'arrowback':
      return <IoArrowBackCircleSharp style={{ width: size, height: size }} />;
    case 'user':
      return <FaUsers style={{ width: size, height: size }} />;
    case 'building':
      return <BiBuildings style={{ width: size, height: size }} />;
    case 'bars':
      return <FaBars style={{ width: size, height: size }} />;
    case 'menudots':
      return <BsThreeDotsVertical style={{ width: size, height: size }} />;
    case 'eye':
      return <FaEye style={{ width: size, height: size }} />;
    default:
      return <BsThreeDotsVertical style={{ width: size, height: size }} />;
  }
};

export default AppIcons;
