const actions = {
  SOCKET_BEGIN: 'SOCKET_BEGIN',
  SOCKET_SUCCESS: 'SOCKET_SUCCESS',
  SOCKET_ERR: 'SOCKET_ERR',
  SOCKET_INSTANCE: 'SOCKET_INSTANCE',

  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  socketBegin: () => {
    return {
      type: actions.SOCKET_BEGIN,
    };
  },

  socketSuccess: (data: any) => {
    return {
      type: actions.SOCKET_SUCCESS,
      data,
    };
  },
  socketInstance: (data: any) => {
    return {
      type: actions.SOCKET_INSTANCE,
      data,
    };
  },

  socketErr: (err: any) => {
    return {
      type: actions.SOCKET_ERR,
      err,
    };
  },
};

export default actions;
