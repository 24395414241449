const actions = {
  USER_BEGIN: 'USER_BEGIN',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_ERR: 'USER_ERR',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  UserBegin: () => {
    return {
      type: actions.USER_BEGIN,
    };
  },

  UserSuccess: (data: any) => {
    return {
      type: actions.USER_SUCCESS,
      data,
    };
  },

  UserErr: (err: any) => {
    return {
      type: actions.USER_ERR,
      err,
    };
  },
};

export default actions;
