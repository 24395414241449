import { message } from 'antd';
import { AxiosError } from 'axios';
import axiosInstance from './axios';
import actions from '../redux/Auth/actions';
const { logoutSuccess } = actions;
const MessageHandler = (response: any, dispatch: any) => {
  if (response?.status !== 200) {
    if (response.status === 401 || response.status === 401) {
      localStorage.removeItem('logedIn');
      dispatch(logoutSuccess(true));
    }
    if (response?.data?.errors) {
      message.error(response?.data?.errors.msg);
    } else if (response?.statusText) {
      message.error(response?.statusText);
    } else {
      message.error('Unknown Error Please Contact Support Team');
    }
  }
};
const getApi = async (endpoint: string, dispatch: any) => {
  const response = await axiosInstance(dispatch)
    .get(endpoint)
    .catch((error: AxiosError) => {
      MessageHandler(error.response, dispatch);
      return error.response;
    });
  return response;
};

const putApi = async (endpoint: string, data: any, dispatch: any) => {
  const response = await axiosInstance(dispatch)
    .put(endpoint, data)
    .catch((error: AxiosError) => {
      MessageHandler(error.response, dispatch);
      return error.response;
    });

  return response;
};
const                                                                     postApi = async (endpoint: string, data: any, dispatch: any) => {
  try {
    const response = await axiosInstance(dispatch)
      .post(endpoint, data)
      .catch((error: AxiosError) => {
        MessageHandler(error.response, dispatch);
        return error.response;
      });

    return response;
  } catch (error) {
    return error;
  }
};
const patchApi = async (endpoint: string, data: any, dispatch: any) => {
  const response = await axiosInstance(dispatch)
    .patch(endpoint, data)
    .catch((error: AxiosError) => {
      MessageHandler(error.response, dispatch);
      return error.response;
    });

  return response;
};
const deleteApi = async (endpoint: string, dispatch: any) => {
  const response = await axiosInstance(dispatch)
    .delete(endpoint)
    .catch((error: AxiosError) => {
      MessageHandler(error.response, dispatch);
      return error.response;
    });

  return response;
};

export { getApi, putApi, postApi, patchApi, deleteApi };
