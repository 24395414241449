/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { Button, Dropdown, Form, Input, Menu, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TableWrapper } from '../../styled';
import { userStatus } from '../../../helper/enum';
import AppIcons from '../../../helper/AppIcons';
import { changePassword, changeStatus } from '../../../redux/Users/actionCreator';
import { validations } from '../../../helper/validations';

const UserTable = () => {
  const { users } = useSelector((state: any) => {
    return {
      users: state.users.data,
    };
  });
  const [modelVisiable, setModelVisiable] = useState(false);
  const [modelData, setModelData]: any = useState({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const expandedRowRender = () => {
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Type of User',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
    ];
    const userDataSource = () => {
      return (users || []).map((obj: any, i: number) => {
        const { Id, firstName, lastName, status, type, email } = obj;
        return {
          key: Id,
          id: i + 1,
          firstName,
          lastName,
          status,
          email,
          type,
        };
      });
    };
    return (
      <TableWrapper>
        <Table
          columns={columns}
          pagination={{
            defaultPageSize: 5,
            total: dataSource().length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </TableWrapper>
    );
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Type of User',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const dataSource = () => {
    return (users || []).map((obj: any, i: number) => {
      const { id, firstName, lastName, status, type, email } = obj;
      return {
        key: id,
        id: i + 1,
        firstName,
        lastName,
        status,
        email,
        type,
        action: (
          <div className="table-actions">
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={() => dispatch(changeStatus(id, userStatus.active))}>
                    {userStatus.active}
                  </Menu.Item>
                  <Menu.Item key="2" onClick={() => dispatch(changeStatus(id, userStatus.deactive))}>
                    {userStatus.deactive}
                  </Menu.Item>
                  <Menu.Item key="3" onClick={() => dispatch(changeStatus(id, userStatus.suspend))}>
                    {userStatus.suspend}
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type="ghost" shape="circle" className="ant-btn-primary">
                {AppIcons('menudots')}
              </Button>
            </Dropdown>
            <Button
              type="ghost"
              shape="circle"
              className="ant-btn-primary"
              onClick={() => {
                setModelVisiable(!modelVisiable);
                setModelData(obj);
              }}
            >
              {AppIcons('eye')}
            </Button>
          </div>
        ),
      };
    });
  };
  const onFinish = async (values: any) => {
    if (values.password === values.confirmPassword) {
      const result: any = dispatch(changePassword(modelData.id, values.password));
      if (result) {
        form.resetFields();
        setModelVisiable(!modelVisiable);
      }
    }
  };
  return (
    <div>
      <TableWrapper>
        <Table
          pagination={{
            defaultPageSize: 5,
            total: dataSource().length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          dataSource={dataSource()}
          columns={columns}
        />
      </TableWrapper>
      <Modal
        visible={modelVisiable}
        onOk={() => form.submit()}
        onCancel={() => setModelVisiable(!modelVisiable)}
        okText="Change"
      >
        <h3>Change Password</h3>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item label="Password" name={'password'} rules={validations.simpleValidation}>
            <Input.Password />
          </Form.Item>
          <Form.Item label="Confirm Password" name={'confirmPassword'} rules={validations.simpleValidation}>
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserTable;
