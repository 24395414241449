export enum roles {
  superAdmin = 'superAdmin',
  admin = 'admin',
  employee = 'employee',
}

export const questionTypes = [
  'tonal patterns',
  'objection',
  'controlling the conversation',
  'script building',
  'selling the three pillars',
  'closing lines',
];

export enum userStatus {
  active = 'active',
  deactive = 'deactive',
  suspend = 'suspend',
}

export enum SocketClientEvent {
  CALL_USER = 'callUser',
  ANSWER_USER = 'answerUser',
  END_CALL = 'endCall',
  CALL_ACCEPTED = 'callAccepted',
  SEND_MY_ID = 'sendMyId',
  USER_CONNECTED = 'userConnected',
  ALL_USERS_ID = 'allusersIds',
  ME = 'me',
}
