const actions = {
  CHANGE_LAYOUT_MODE_BEGIN: 'CHANGE_LAYOUT_MODE_BEGIN',
  CHANGE_LAYOUT_MODE_SUCCESS: 'CHANGE_LAYOUT_MODE_SUCCESS',
  CHANGE_LAYOUT_MODE_ERR: 'CHANGE_LAYOUT_MODE_ERR',
  CHANGE_SIDENAV_MODE_SUCCESS: 'CHANGE_SIDENAV_MODE_SUCCESS',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  changeLayoutBegin: () => {
    return {
      type: actions.CHANGE_LAYOUT_MODE_BEGIN,
    };
  },

  changeLayoutSuccess: (data: any) => {
    return {
      type: actions.CHANGE_LAYOUT_MODE_SUCCESS,
      data,
    };
  },
  changeSideNavSuccess: (data: any) => {
    return {
      type: actions.CHANGE_SIDENAV_MODE_SUCCESS,
      data,
    };
  },

  changeLayoutErr: (err: any) => {
    return {
      type: actions.CHANGE_LAYOUT_MODE_ERR,
      err,
    };
  },
};

export default actions;
