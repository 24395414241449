import MenuDataModal from '../class/MenuDataModal';

export const MenuId = {
  question: 1,
  users: 2,
};
export const MenuIcon = {
  question: 'Home',
  users: 'infocircle',
};
export const MenuLink = {
  question: '/',
  users: '/admin/users',
};

export const MenuData: MenuDataModal[] = [
  {
    id: MenuId.question,
    text: 'Libraries',
    icon: MenuIcon.question,
    link: MenuLink.question,
  },
  {
    id: MenuId.users,
    text: 'users',
    icon: MenuIcon.users,
    link: MenuLink.users,
  },
];
