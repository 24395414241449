import actions from './actions';
import reduxActionModal from '../../class/reduxActionModal';

interface Socket {
  loading: boolean;
  connected: boolean;
  error: any;
  socketInstance: any;
}

const initialState: Socket = {
  loading: false,
  connected: false,
  error: null,
  socketInstance: null,
};

const { SOCKET_BEGIN, SOCKET_SUCCESS, SOCKET_ERR, LOGOUT_SUCCESS, SOCKET_INSTANCE } = actions;

const Reducer = (state: Socket = initialState, action: reduxActionModal) => {
  const { type, data, err } = action;
  switch (type) {
    case SOCKET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case SOCKET_SUCCESS:
      return {
        ...state,
        connected: data,
        notesLoading: false,
      };
    case SOCKET_INSTANCE:
      return {
        ...state,
        socketInstance: data,
      };
    case SOCKET_ERR:
      return {
        ...state,
        error: err,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Reducer;
