import { FC, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import DashboardStruct from './pages/User/index';
import Login from './pages/Login';
import ErrorPage from './pages/ErrorPage';
import store from './redux/store';
import './scss/main.scss';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { AppWrapper } from './Styled';
import { lightTheme, darkTheme } from './theme';
import superAdmin from './pages/Admin';
import Admin from './pages/companyAdmin/index';
import 'antd/dist/antd.min.css';
import { roles } from './helper/enum';
import Home from './pages/Home';
import Register from './pages/Register';
import Success from './pages/Register/Success';
import AddDemo from './pages/Demo/AddDemo';
import JoinWaitlist from './pages/JoinWaitlist';
const Preloader = lazy(() => import('./components/Preloader'));

const ProviderConfig: FC = () => {
  const { theme } = useSelector((state: any) => {
    return {
      theme: state.ChangeLayoutMode.theme,
    };
  });
  return (
    <Router>
      <ThemeProvider theme={theme ? lightTheme : darkTheme}>
        <Suspense fallback={<Preloader />}>
          <AppWrapper>
            <Switch>
              <PublicRoute restricted={true} Component={Login} path="/" exact />
              <PublicRoute Component={Admin} path={'/admin'} role={roles.admin} />
              <PublicRoute Component={superAdmin} path={'/superAdmin'} role={roles.superAdmin} />
              <PrivateRoute Component={DashboardStruct} path={'/user'} role={roles.employee} />
              <PublicRoute Component={Success} path={'/success'} />
              <PublicRoute Component={AddDemo} path={'/demo'} />
              <PublicRoute Component={AddDemo} path={'/download-pdf'} />
              <PublicRoute Component={JoinWaitlist} path="/join-waitlist" exact />
              <PublicRoute restricted={false} Component={ErrorPage} path="*" exact />
            </Switch>
          </AppWrapper>
        </Suspense>
      </ThemeProvider>
    </Router>
  );
};
function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}
export default App;
