import React from 'react';
import DemoTable from './DemoTable';

const Dashboard = () => {
  return (
    <div>
      <h1 className="text-center">Request for Demo</h1>
      <DemoTable />
    </div>
  );
};

export default Dashboard;
