import React from 'react';
import { BiLogOut } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { TopNavWrapper } from './styled';
// import Switch from './Switch';
import { PoweroffOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import SwitchBtn from './Switch';
import Logo from '../assets/img/logo.svg';
import { logout } from '../redux/Auth/actionCreator';
import AppIcons from '../helper/AppIcons';
import { changeLayoutMode } from '../redux/Layout/actionCreator';
import { changeSideNavMode } from '../redux/Layout/actionCreator';
function TopNavBar() {
  const { sideNavMode } = useSelector((state: any) => {
    return {
      sideNavMode: state.ChangeLayoutMode.sideNavMode,
    };
  });

  const dispatch = useDispatch();
  const { theme } = useSelector((state: any) => {
    return {
      theme: state.ChangeLayoutMode.theme,
    };
  });
  return (
    <TopNavWrapper>
      <div className={!theme ? 'toggle-container-light' : 'toggle-container'}>
        <div className="flex-icon-heading">
          <div className={!theme ? 'Affective-AI-Heading-light' : 'Affective-AI-Heading'}>Affective AI</div>
          <Link to="/liai">
            <img src={Logo} alt="logo" className={!theme ? 'liailogo-light' : 'liailogo'} style={{ marginTop: '4px' }} />
          </Link>
        </div>
        <div style={{ display: 'flex', paddingTop: '5px' }}>
          <div className={!theme ? 'Toggle-btn-light' : 'Toggle-DarkNight'}>
            Light
            <Switch className="SwitchChecked" onChange={(event) => dispatch(changeLayoutMode(event))} />
            Dark
          </div>
          <div
          style={{marginTop:"5px"}}
            className={!theme ? 'PoweroffOutlined-icon' : 'PoweroffOutlined-icon'}
            onClick={() => dispatch(logout())}
          >
            <PoweroffOutlined />
          </div>
        </div>
      </div>
    </TopNavWrapper>
  );
}

export default TopNavBar;
