const actions = {
  PAYMENT_BEGIN: 'PAYMENT_BEGIN',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_ERR: 'PAYMENT_ERR',
  PLANS_BEGIN: 'PLANS_BEGIN',
  PLANS_SUCCESS: 'PLANS_SUCCESS',
  PLANS_ERR: 'PLANS_ERR',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  paymentPlanBegin: () => {
    return {
      type: actions.PLANS_BEGIN,
    };
  },

  paymentPlanSuccess: (data: any) => {
    return {
      type: actions.PLANS_SUCCESS,
      data,
    };
  },

  paymentPlanErr: (err: any) => {
    return {
      type: actions.PLANS_ERR,
      err,
    };
  },
  paymentBegin: () => {
    return {
      type: actions.PAYMENT_BEGIN,
    };
  },

  paymentSuccess: (data: any) => {
    return {
      type: actions.PAYMENT_SUCCESS,
      data,
    };
  },

  paymentErr: (err: any) => {
    return {
      type: actions.PAYMENT_ERR,
      err,
    };
  },
};

export default actions;
