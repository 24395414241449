import React from 'react';
import { Col, Row, Form, Input, Button } from 'antd';
import logo from '../../../assets/img/logo.svg';
const Settings = () => {
  return (
    <div className="container">
      <Form layout="vertical">
        <Row gutter={[20, 20]}>
          <Col sm={24} className="justify-content-center d-flex">
            <img src={logo} alt="Logo" />
          </Col>
          <Col md={24} lg={12}>
            <Form.Item name="firstName" label="First Name">
              <Input />
            </Form.Item>
          </Col>
          <Col md={24} lg={12}>
            <Form.Item name="lastName" label="Last Name">
              <Input />
            </Form.Item>
          </Col>
          <Col md={24} lg={12}>
            <Button type="ghost">Change Password</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Settings;
