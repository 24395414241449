import helperFunctions from './helperFunctions';
interface validationsTypes {
  emailValid: any;
  officialEmail: any;
  checkBoxValid: any;
  simpleValidation: any;
  time15: any;
  urlValid: any;
  linkedInurlValid: any;
  datePicker: any;
  dateRange: any;
  password: any;
  phone: any;
}
export const validations: validationsTypes = {
  emailValid: [
    { required: true, message: 'Email required' },
    {
      type: 'email',
      message: 'This field must be a valid Email',
      validator: (rule: any, value: any, cb: any) => {
        if (helperFunctions.isValidEmail(value)) {
          cb();
        } else {
          cb('The field must be a valid ');
        }
      },
    },
  ],
  officialEmail: [
    { required: true, message: 'Email is required' },
    {
      type: 'email',
      validator: (rule: any, value: any, cb: any) => {
        if (helperFunctions.officialEmail(value)) {
          cb();
        } else {
          cb('This field must be an official email');
        }
      },
    },
  ],
  checkBoxValid: [
    {
      required: true,
      message: 'Please checkmark to proceed',
      transform: (value: string) => value || undefined, // Those two lines
      type: 'boolean', // Do the magic
    },
  ],
  simpleValidation: [{ required: true, message: 'This field is required' }],
  time15: [
    { required: true, message: 'This field is required' },
    {
      type: 'number',
      min: 0,
      max: 15,
    },
  ],
  urlValid: [
    { required: true, message: 'This field is required' },
    { type: 'url', message: 'This field must be a valid URL' },
  ],
  linkedInurlValid: [
    {
      required: true,
      type: 'url',
      message: 'This field must be a LinkedIn URL',
      validator: (rule: any, value: any, cb: any) => {
        if (helperFunctions.linkValidations.linkedInValid(value)) {
          cb();
        } else {
          cb('The field must be a valid LinkedIn URL');
        }
      },
    },
  ],
  datePicker: [
    {
      required: true,
      type: 'object',
      message: 'This field must be a Valid Date',
    },
  ],
  dateRange: [
    {
      type: 'array',
      required: true,
      message: 'Please select both Date!',
    },
  ],

  password: [
    { required: true, message: 'Password required' },
    {
      min: 8,
      max: 15,
      message: 'Password length must be between 8 and 15 characters',
    },
  ],
  phone: [{ required: true, message: 'Phone Number is required' }],
};
