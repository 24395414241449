// @ts-nocheck
import { useState } from 'react';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import 'bootstrap/dist/css/bootstrap.css';
import googleIcon from '../../assets/img/googleIcon.jpg';
import jwt from 'jwt-decode';
import ImageLogo from '../../assets/img/logo.svg';
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs';
import { TiTick } from 'react-icons/ti';
import { BiSolidUpArrow } from 'react-icons/bi';
import './waitlist.css';
import { Checkbox } from 'antd';

import firebase from 'firebase/app';
import 'firebase/auth';
const firebaseConfig = {
  apiKey: 'AIzaSyCwuvSQsLvexOOSIf5sas8v71TJcNR8LrE',
  authDomain: 'affectiveai.firebaseapp.com',
  projectId: 'affectiveai',
  storageBucket: 'affectiveai.appspot.com',
  messagingSenderId: '516491515577',
  appId: '1:516491515577:web:88b5241946f04515dd6d68',
  measurementId: 'G-WXGE23BJKB',
};
firebase.initializeApp(firebaseConfig);
interface data {
  name: string;
  company: string;
  phone: string;
  email: string;
  employees: string;
}

const JoinWaitlist = () => {
  // const navigate = useNavigate();
  const [data, setData] = useState<data>({
    name: '',
    email: '',
    company: '',
    phone: '',
  });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [step, setStep] = useState(1);
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail);
    setIsEmailValid(isValid);
    setData((prev) => (prev = { ...prev, email: newEmail }));
  };
  const handleNext = () => {
    if (isEmailValid) setStep(step + 1);
  };
  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };
  const [isClickedLeft, setIsClickedLeft] = useState(false);
  const [checkBox, setCheckBox] = useState<string[]>([]);
  const [checkBoxBuilding, setCheckBoxBuilding] = useState<string[]>([]);
  const [checkBoxHearAbout, setCheckBoxHearAbout] = useState<string[]>([]);
  const [checkBoxDescribeRole, setCheckBoxDescribeRole] = useState<string[]>([]);
  const [checkBoxWorkplace, setCheckBoxWorkplace] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);
  const [otherValue, setOtherValue] = useState('');
  const isOtherChecked = checkBox.includes('Others');
  const [buildingValue, setbuildingValue] = useState('');
  const isBuildingValue = checkBoxBuilding.includes('Others');
  const [hearValue, setHearValue] = useState('');
  const isHearValue = checkBoxHearAbout.includes('Others');
  const [describeValue, setDescribeValue] = useState('');
  const isDescribeValue = checkBoxDescribeRole.includes('Others');
  const handleChange = (e) => {
    data[e.target.name] = e.target.value;
    setData((prev) => (prev = { ...data }));
  };

  const join = (e) => {
    e.preventDefault();
    setLoading(true); // Start the loader
    const formEle = document.querySelector('form');
    const formDatab = new FormData(formEle);
    fetch(
      'https://script.google.com/macros/s/AKfycbzp9mngF_1i8rHRxuj966cpczMiDqOjSeCqx10vIJ7JgKWVa2zyyUfqQrEcMyyOWY3mog/exec',
      {
        method: 'POST',
        body: formDatab,
      },
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false); // Stop the loader
        setSuccess(true); // Set success state to true
        // ...rest of your code
      })
      .catch((error) => {
        console.log(error);
        setLoading(false); // Stop the loader on error
      });
  };
  const decode = (token) => {
    let userInfo = jwt(token);
    setData((prev) => (prev = { ...data, name: userInfo.name, email: userInfo.email }));
    setIsEmailValid(true);
  };

  const handleCheckboxClick = (title: string) => {
    if (!checkBox.includes(title)) {
      setCheckBox([...checkBox, title]);
    } else {
      const filter = checkBox.filter((e) => e !== title);
      setCheckBox(filter);

      if (title === 'Others') {
        if (otherValue) {
          setCheckBox(filter.filter((e) => e !== otherValue));
        }
        setOtherValue('');
      }
    }
  };
  const handleBuildingCheckboxClick = (title: string) => {
    if (!checkBoxBuilding.includes(title)) {
      setCheckBoxBuilding([...checkBoxBuilding, title]);
    } else {
      const filter = checkBoxBuilding.filter((e) => e !== title);
      setCheckBoxBuilding(filter);

      if (title === 'Others') {
        if (buildingValue) {
          setCheckBoxBuilding(filter.filter((e) => e !== buildingValue));
        }
        setbuildingValue('');
      }
    }
  };
  const handleHearCheckboxClick = (title: string) => {
    if (!checkBoxHearAbout.includes(title)) {
      setCheckBoxHearAbout([...checkBoxHearAbout, title]);
    } else {
      const filter = checkBoxHearAbout.filter((e) => e !== title);
      setCheckBoxHearAbout(filter);

      if (title === 'Others') {
        if (hearValue) {
          setCheckBoxHearAbout(filter.filter((e) => e !== hearValue));
        }
        setHearValue('');
      }
    }
  };
  const handleDescribeCheckboxClick = (title: string) => {
    if (!checkBoxDescribeRole.includes(title)) {
      setCheckBoxDescribeRole([...checkBoxDescribeRole, title]);
    } else {
      const filter = checkBoxDescribeRole.filter((e) => e !== title);
      setCheckBoxDescribeRole(filter);

      if (title === 'Others') {
        if (describeValue) {
          setCheckBoxDescribeRole(filter.filter((e) => e !== describeValue));
        }
        setDescribeValue('');
      }
    }
  };
  const workplace = [
    {
      title: '1-5 employees',
    },
    {
      title: '6-10 employees',
    },
    {
      title: '11-50 employees',
    },
    {
      title: '51-200 employees',
    },
    {
      title: '201-1,000 employees',
    },
    {
      title: '1,001+ employees',
    },
  ];
  const describeRole = [
    {
      title: 'CEO',
    },
    {
      title: 'Entrepreneur',
    },
    {
      title: 'Developer',
    },
    {
      title: 'Marketing Manager',
    },
    {
      title: 'Sales Director',
    },
    {
      title: 'Customer Service Manager',
    },
    {
      title: 'Training Manager',
    },
    {
      title: 'Student',
    },
    {
      title: 'Others',
    },
  ];
  const HearAbout = [
    {
      title: 'Friend / Colleague',
    },
    {
      title: 'X formally known as Twitter',
    },
    {
      title: 'Youtube',
    },
    {
      title: 'Instagram',
    },
    {
      title: 'Tiktok',
    },
    {
      title: 'Search engine (e.g. Google, Bing)',
    },
    {
      title: 'Others',
    },
  ];
  const buildingWebsites = [
    {
      title: 'Technology',
    },
    {
      title: 'Therapist Psychiatrist',
    },
    {
      title: 'Business Services',
    },
    {
      title: 'Retail',
    },
    {
      title: 'Communication',
    },
    {
      title: 'Consumer Goods',
    },
    {
      title: 'Education',
    },
    {
      title: 'Energy & Utilities',
    },
    {
      title: 'Financial Services',
    },
    {
      title: 'Blockchain',
    },
    {
      title: 'Heathcare & Life Sciences',
    },
    {
      title: 'Manufacturing',
    },
    {
      title: 'Media',
    },
    {
      title: 'Nonprofit',
    },
    {
      title: 'Public sector',
    },
    {
      title: 'Automotive',
    },
    {
      title: 'Government',
    },
    {
      title: 'Travel Transportation & Hospitality',
    },
    {
      title: 'Others',
    },
  ];
  const tools = [
    {
      title: 'CRM System',
    },
    {
      title: 'VOiP',
    },
    {
      title: 'Data Analytics Solutions',
    },
    {
      title: 'Training Simulation',
    },
    {
      title: 'Others',
    },
  ];
  const signIngoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await firebase.auth().signInWithPopup(provider);
      await firebase.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          console.log(authUser?.displayName, 'auth');
          setData(
            (prev) =>
              (prev = {
                ...data,
                name: authUser.displayName,
                email: authUser.email,
              }),
          );
          setIsEmailValid(true);
        } else {
          return { displayName: '', email: '' };
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const Loginpage = () => {
    return (
      <div className="col-12 col-md-12 flex-centerItems" style={{ marginTop: '30px' }}>
        <div className="col-12  col-sm-8 col-md-6 col-lg-4 col-xl-3">
          <div className="FormContainer-login">
            <div className="WaitList-title">Join Affective AI WaitList</div>
            <div className="ContinueGoogleBtn">
              <div style={{ display: 'flex' }} onClick={() => signIngoogle()}>
                <img src={googleIcon} style={{ height: '23px', width: '23px' }} />
                <div className="googleBtnText">Continue with Google</div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <hr className="borderHorizontal"></hr>
              <div className="orTitle">OR</div>
              <hr className="borderHorizontal"></hr>
            </div>
            <input
              type="text"
              name="name"
              className="waitlist-input mt-2"
              placeholder="Name"
              value={data.name}
              onChange={(e) => handleChange(e)}
            />
            <input
              type="email"
              required
              name="email"
              className="waitlist-input mt-2"
              placeholder="Enter your email to continue..."
              value={data.email}
              onChange={handleEmailChange}
            />
            <input
              type="text"
              name="company"
              placeholder="Company"
              className="waitlist-input mt-2"
              value={data.company}
              onChange={(e) => handleChange(e)}
            />
            <input
              type="number"
              name="phone"
              className="mt-2"
              placeholder="Phone"
              className="waitlist-input mt-2"
              value={data.phone}
              onChange={(e) => handleChange(e)}
            />
            <div
              className={`continue-btn ${
                isEmailValid && data.name.length > 0 && data.phone.length > 0 ? 'active-clicked' : ''
              }`}
              onClick={handleNext}
            >
              <div style={{ display: 'flex' }}>
                <div
                  className={`Continue-BtnText ${
                    isEmailValid && data.name.length > 0 && data.phone.length > 0 ? 'active-color' : ''
                  }`}
                >
                  Continue
                </div>
                <BsArrowRightShort
                  className={`BsArrowRightShort-icon ${
                    isEmailValid && data.name.length > 0 && data.phone.length > 0 ? 'active-BsArrowRightShort-icon' : ''
                  }`}
                />
              </div>
            </div>

            <div className="text-TermsConditions">
              By clicking "Create account" or "Continue with Google", you agree to the Affective's{' '}
              <span className="textUnderLine-TermsConditions">Terms & Conditions</span> and{' '}
              <span className="textUnderLine-TermsConditions">Privacy Policy</span>{' '}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Tab1 = () => {
    return (
      <div className="col-12 col-md-12 flex-centerItems mt-5">
        <div className="col-12  col-sm-8 col-md-6 col-lg-4 col-xl-3">
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
              <BiSolidUpArrow className="BiSolidUpArrow-icon" />
            </div>
            <div className="active-InactiveTabs">
              <div className="Inactive-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="Inactive-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="Inactive-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="Inactive-Container"></div>
            </div>
          </div>
          <div className="FormContainer">
            <div className="familiarTool-title">What tools are you currently utilising? </div>
            {tools.map((item, i) => (
              <div className="Container-Checkbox" onClick={() => handleCheckboxClick(item?.title)}>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <div>
                    {' '}
                    <Checkbox checked={checkBox.includes(item?.title)}></Checkbox>
                  </div>
                  <div className="Container-CheckboxText">{item?.title}</div>
                </div>
              </div>
            ))}
            {isOtherChecked && (
              <input
                className="waitlist-input mt-2"
                type="text"
                placeholder="Tool"
                value={otherValue}
                onChange={(e) => setOtherValue(e.target.value)}
                onBlur={(e) => {
                  if (!checkBox.includes(e.target.value) && e.target.value) {
                    // checkBox.push(e.target.value);
                    setCheckBox([...checkBox, e.target.value]);
                  }
                }}
              />
            )}

            <div
              className={`continueTool-btn ${
                (isOtherChecked && otherValue?.length > 0) || (checkBox?.length > 0 && !isOtherChecked)
                  ? 'active-clicked'
                  : ''
              }`}
              onClick={() =>
                (isOtherChecked && otherValue?.length > 0) || (checkBox?.length > 0 && !isOtherChecked)
                  ? handleNext()
                  : null
              }
            >
              <div style={{ display: 'flex' }}>
                <div
                  className={`Continue-BtnText ${
                    (isOtherChecked && otherValue?.length > 0) || (checkBox?.length > 0 && !isOtherChecked)
                      ? 'active-color'
                      : ''
                  }`}
                >
                  Continue
                </div>
                <BsArrowRightShort
                  className={`BsArrowRightShort-icon ${checkBox?.length > 0 ? 'active-BsArrowRightShort-icon' : ''}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Tab2 = () => {
    return (
      <div className="col-12 col-md-12 flex-centerItems mt-5">
        <div className="col-12  col-sm-8 col-md-6 col-lg-4 col-xl-3">
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
              <BiSolidUpArrow className="BiSolidUpArrow-icon" />
            </div>
            <div className="active-InactiveTabs">
              <div className="Inactive-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="Inactive-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="Inactive-Container"></div>
            </div>
          </div>
          <div className="FormContainer">
            <div className="familiarTool-title">Which of the following best describe the industry you’re in?</div>
            {buildingWebsites.map((item, i) => (
              <div className="Container-Checkbox" onClick={() => handleBuildingCheckboxClick(item?.title)}>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Checkbox value={item?.title} checked={checkBoxBuilding.includes(item?.title)}></Checkbox>
                  <div className="Container-CheckboxText">{item?.title}</div>
                </div>
              </div>
            ))}
            {isBuildingValue && (
              <input
                type="text"
                value={buildingValue}
                name="Addtools"
                className="waitlist-input mt-2"
                placeholder="Industry"
                onChange={(e) => setbuildingValue(e.target.value)}
                onBlur={(e) => {
                  if (!checkBoxBuilding.includes(e.target.value) && e.target.value) {
                    setCheckBoxBuilding([...checkBoxBuilding, 'Others', e.target.value]);
                  }
                }}
              />
            )}
            <div
              style={{
                display: 'flex',
              }}
            >
              <div className={`arrowBack-btn ${isClickedLeft ? 'active-arrowBack-btn' : ''}`} onClick={handleBack}>
                <BsArrowLeftShort
                  className={`BsArrowleftShort-icon ${isClickedLeft ? 'active-BsArrowRightShort-icon' : ''}`}
                />
              </div>
              <div
                className={`continueTool-btn ${
                  (isBuildingValue && buildingValue?.length > 0) || (checkBoxBuilding?.length > 0 && !isBuildingValue)
                    ? 'active-clicked'
                    : ''
                }`}
                onClick={() =>
                  (isBuildingValue && buildingValue?.length > 0) || (checkBoxBuilding?.length > 0 && !isBuildingValue)
                    ? handleNext()
                    : null
                }
              >
                <div style={{ display: 'flex' }}>
                  <div
                    className={`Continue-BtnText ${
                      (isBuildingValue && buildingValue?.length > 0) ||
                      (checkBoxBuilding?.length > 0 && !isBuildingValue)
                        ? 'active-color'
                        : ''
                    }`}
                  >
                    Continue
                  </div>
                  <BsArrowRightShort
                    className={`BsArrowRightShort-icon ${
                      checkBoxBuilding?.length > 0 ? 'active-BsArrowRightShort-icon' : ''
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Tab3 = () => {
    return (
      <div className="col-12 col-md-12 flex-centerItems mt-5">
        <div className="col-12  col-sm-8 col-md-6 col-lg-4 col-xl-3">
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
              <BiSolidUpArrow className="BiSolidUpArrow-icon" />
            </div>
            <div className="active-InactiveTabs">
              <div className="Inactive-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="Inactive-Container"></div>
            </div>
          </div>
          <div className="FormContainer">
            <div className="hearAbout-title">How did you first hear about Affective AI?</div>
            {HearAbout.map((item, i) => (
              <div className="Container-Checkbox" onClick={() => handleHearCheckboxClick(item?.title)}>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Checkbox value={item?.title} checked={checkBoxHearAbout.includes(item?.title)}></Checkbox>
                  <div className="Container-CheckboxText">{item?.title}</div>
                </div>
              </div>
            ))}
            {isHearValue && (
              <input
                type="text"
                value={hearValue}
                name="hearValue"
                className="waitlist-input mt-2"
                placeholder="Hear about"
                onChange={(e) => setHearValue(e.target.value)}
                onBlur={(e) => {
                  if (!checkBoxHearAbout.includes(e.target.value) && e.target.value) {
                    // checkBox.push(e.target.value);
                    setCheckBoxHearAbout([...checkBoxHearAbout, 'Others', e.target.value]);
                  }
                }}
              />
            )}
            <div
              style={{
                display: 'flex',
              }}
            >
              <div className={`arrowBack-btn ${isClickedLeft ? 'active-arrowBack-btn' : ''}`} onClick={handleBack}>
                <BsArrowLeftShort
                  className={`BsArrowleftShort-icon ${isClickedLeft ? 'active-BsArrowRightShort-icon' : ''}`}
                />
              </div>
              <div
                className={`continueTool-btn ${
                  (isHearValue && hearValue?.length > 0) || (checkBoxHearAbout?.length > 0 && !isHearValue)
                    ? 'active-clicked'
                    : ''
                }`}
                onClick={() =>
                  (isHearValue && hearValue?.length > 0) || (checkBoxHearAbout?.length > 0 && !isHearValue)
                    ? handleNext()
                    : null
                }
              >
                <div style={{ display: 'flex' }}>
                  <div
                    className={`Continue-BtnText ${
                      (isHearValue && hearValue?.length > 0) || (checkBoxHearAbout?.length > 0 && !isHearValue)
                        ? 'active-color'
                        : ''
                    }`}
                  >
                    Continue
                  </div>
                  <BsArrowRightShort
                    className={`BsArrowRightShort-icon ${
                      checkBoxHearAbout?.length > 0 ? 'active-BsArrowRightShort-icon' : ''
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Tab4 = () => {
    return (
      <div className="col-12 col-md-12 flex-centerItems mt-5">
        <div className="col-12  col-sm-8 col-md-6 col-lg-4 col-xl-3">
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
              <BiSolidUpArrow className="BiSolidUpArrow-icon" />
            </div>
            <div className="active-InactiveTabs">
              <div className="Inactive-Container"></div>
            </div>
          </div>
          <div className="FormContainer">
            <div className="familiarTool-title">What best describes your role?</div>
            {describeRole.map((item, i) => (
              <div className="Container-Checkbox" onClick={() => handleDescribeCheckboxClick(item?.title)}>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Checkbox value={item?.title} checked={checkBoxDescribeRole.includes(item?.title)}></Checkbox>
                  <div className="Container-CheckboxText">{item?.title}</div>
                </div>
              </div>
            ))}
            {isDescribeValue && (
              <input
                type="text"
                value={describeValue}
                name="Addtools"
                className="waitlist-input mt-2"
                placeholder="Role"
                onChange={(e) => setDescribeValue(e.target.value)}
                onBlur={(e) => {
                  if (!checkBoxDescribeRole.includes(e.target.value) && e.target.value) {
                    // checkBox.push(e.target.value);
                    setCheckBoxDescribeRole([...checkBoxDescribeRole, 'Others', e.target.value]);
                  }
                }}
              />
            )}
            <div
              style={{
                display: 'flex',
              }}
            >
              <div className={`arrowBack-btn ${isClickedLeft ? 'active-arrowBack-btn' : ''}`} onClick={handleBack}>
                <BsArrowLeftShort
                  className={`BsArrowleftShort-icon ${isClickedLeft ? 'active-BsArrowRightShort-icon' : ''}`}
                />
              </div>
              <div
                className={`continueTool-btn ${
                  (isDescribeValue && describeValue?.length > 0) ||
                  (checkBoxDescribeRole?.length > 0 && !isDescribeValue)
                    ? 'active-clicked'
                    : ''
                }`}
                onClick={() =>
                  (isDescribeValue && describeValue?.length > 0) ||
                  (checkBoxDescribeRole?.length > 0 && !isDescribeValue)
                    ? handleNext()
                    : null
                }
              >
                <div style={{ display: 'flex' }}>
                  <div
                    className={`Continue-BtnText ${
                      (isDescribeValue && describeValue?.length > 0) ||
                      (checkBoxDescribeRole?.length > 0 && !isDescribeValue)
                        ? 'active-color'
                        : ''
                    }`}
                  >
                    Continue
                  </div>
                  <BsArrowRightShort
                    className={`BsArrowRightShort-icon ${
                      checkBoxDescribeRole?.length > 0 ? 'active-BsArrowRightShort-icon' : ''
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Tab5 = () => {
    return (
      <div className="col-12 col-md-12 flex-centerItems mt-5">
        <div className="col-12  col-sm-8 col-md-6 col-lg-4 col-xl-3">
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
            </div>
            <div className="active-InactiveTabs">
              <div className="active-Container"></div>
              <BiSolidUpArrow className="BiSolidUpArrow-icon" />
            </div>
          </div>
          <div className="FormContainer">
            <div className="hearAbout-title">How many people are employed at your workplace?</div>
            {workplace.map((item, i) => (
              <div
                className="Container-Checkbox"
                onClick={() => {
                  if (!checkBoxWorkplace.includes(item?.title)) {
                    checkBoxWorkplace.push(item?.title);
                    setCheckBoxWorkplace([...checkBoxWorkplace]);
                  } else {
                    const filter = checkBoxWorkplace.filter((e) => e !== item?.title);
                    setCheckBoxWorkplace(filter);
                  }
                }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Checkbox value={item?.title} checked={checkBoxWorkplace.includes(item?.title)}></Checkbox>
                  <div className="Container-CheckboxText">{item?.title}</div>
                </div>
              </div>
            ))}
            <div
              style={{
                display: 'flex',
              }}
            >
              <div className={`arrowBack-btn ${isClickedLeft ? 'active-arrowBack-btn' : ''}`} onClick={handleBack}>
                <BsArrowLeftShort
                  className={`BsArrowleftShort-icon ${isClickedLeft ? 'active-BsArrowRightShort-icon' : ''}`}
                />
              </div>
              <form className="w-100">
                <input type="hidden" name="name" value={data.name} />
                <input type="hidden" name="email" value={data.email} />
                <input type="hidden" name="company" value={data.company} />
                <input type="hidden" name="phone" value={data.phone} />
                <input type="hidden" name="tools" value={checkBox} />
                <input type="hidden" name="website" value={checkBoxBuilding} />
                <input type="hidden" name="hear" value={checkBoxHearAbout} />
                <input type="hidden" name="role" value={checkBoxDescribeRole} />
                <input type="hidden" name="employees" value={checkBoxWorkplace} />
                <div>
                  <div
                    // type="submit"
                    disabled={checkBoxWorkplace?.length === 0}
                    className={`continueTool-btn ${checkBoxWorkplace?.length > 0 ? 'active-clicked' : 'submit-btn'}`}
                    onClick={(e) => join(e)}
                  >
                    <div style={{ display: 'flex' }}>
                      <div className={`Continue-BtnText ${checkBoxWorkplace?.length > 0 ? 'active-color' : ''}`}>
                        {loading ? 'Loading...' : success ? 'Success' : 'Finish'}
                      </div>

                      {!loading && (
                        <BsArrowRightShort
                          className={`BsArrowRightShort-icon ${
                            checkBoxWorkplace?.length > 0 ? 'active-BsArrowRightShort-icon' : ''
                          }`}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!success ? (
        <div className="waitListContainer">
          <div className="col-12 col-md-6 position-relative d-flex justify-content align-items-center">
            <div style={{ display: 'flex' }}>
              <img src={ImageLogo} style={{ width: '80px' }} />
              <div className="Affective-Heading">affective ai</div>
            </div>
          </div>
          {step === 1 && Loginpage()}
          {step === 2 && Tab1()}
          {step === 3 && Tab2()}
          {step === 4 && Tab3()}
          {step === 5 && Tab4()}
          {step === 6 && Tab5()}
        </div>
      ) : (
        <>
          <div className="col-12 col-md-12 flex-centerItems itemsCenter" style={{ marginTop: '-20px' }}>
            <div className="col-12  col-sm-8 col-md-6 col-lg-4 col-xl-3">
              <div className="FormContainer-login">
                <h5 className="text-center">Thank you for joining our waitlist</h5>
                <button
                  className="btn btn-primary text-white mt-4"
                  onClick={() => window.open('https://www.affectiveai.com', '_self')}
                >
                  Go Back To Home
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default JoinWaitlist;
