import MenuDataModal from '../class/MenuDataModal';
export const MenuId = {
  AffectiveAI: 1,
  CRM: 2,
  TargetProgress: 3,
  TeamManagement: 4,
  TrainingRoom: 5,
  Setting: 6,
  FullName: 7,
  Numbers: 8,
  Email: 9,
  LeadProgress: 10,
  Notes: 11,
};
export const MenuIcon = {
  AffectiveAI: 'home',
  CRM: 'infocircle',
  TargetProgress: 'userincircle',
  TeamManagement: 'team',
  TrainingRoom: 'traning',
  Setting: 'setting',
  dropDown: 'arrowForward',
  back: 'ArrowBack',
  users: 'infocircle',
  companies: 'buildings',
};
export const MenuLink = {
  AffectiveAI: '/user/',
  CRM: '/user/crm',
  TargetProgress: '/user/TargetProgress',
  TeamManagement: '/user/TeamManagement',
  TrainingRoom: '/user/TrainingRoom',
  Setting: '/user/Setting',
  FullName: '/user/FullName',
  Numbers: '/user/Numbers',
  Email: '/user/Email',
  LeadProgress: '/user/LeadProgress',
  Notes: '/user/Notes',
};

export const MenuData: MenuDataModal[] = [
  {
    id: MenuId.AffectiveAI,
    text: 'Affective AI',
    icon: MenuIcon.AffectiveAI,
    link: MenuLink.AffectiveAI,
  },
  {
    id: MenuId.CRM,
    text: 'CRM',
    icon: MenuIcon.CRM,
    link: MenuLink.CRM,
    subMenu: [
      {
        id: MenuId.CRM,
        text: 'CRM',
        link: MenuLink.CRM,
      },
      {
        id: MenuId.FullName,
        text: 'Full Name',
        link: MenuLink.FullName,
      },
      {
        id: MenuId.Numbers,
        text: 'Numbers',
        link: MenuLink.Numbers,
      },
      {
        id: MenuId.Email,
        text: 'Email',
        link: MenuLink.Email,
      },
      {
        id: MenuId.LeadProgress,
        text: 'Lead Progress',
        link: MenuLink.LeadProgress,
      },
      {
        id: MenuId.Notes,
        text: 'Notes',
        link: MenuLink.Notes,
      },
    ],
  },
  {
    id: MenuId.TargetProgress,
    text: 'Target Progress',
    icon: MenuIcon.TargetProgress,
    link: MenuLink.TargetProgress,
  },
  {
    id: MenuId.TeamManagement,
    text: 'Team Management',
    icon: MenuIcon.TeamManagement,
    link: MenuLink.TeamManagement,
  },
  {
    id: MenuId.TrainingRoom,
    text: 'Training Room',
    icon: MenuIcon.TrainingRoom,
    link: MenuLink.TrainingRoom,
  },
  {
    id: MenuId.Setting,
    text: 'Setting',
    icon: MenuIcon.Setting,
    link: MenuLink.Setting,
  },
];
