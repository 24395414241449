import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import helperFunctions from '../../../helper/helperFunctions';
import { getCRM, getNotes } from '../../../redux/CRM/actionCreator';
import { Main } from '../../styled';

const CRMHistory = () => {
  const { notes, CRMData } = useSelector((state: any) => {
    return {
      CRMData: state.CRM.data,
      notes: state.CRM.notes,
    };
  });
  const prams: any = useParams();
  const dispatch = useDispatch();
  const [clientData, setclientData]: any = useState(null);
  useEffect(() => {
    dispatch(getNotes(prams.id));
    dispatch(getCRM());
  }, []);
  useEffect(() => {
    if (CRMData) {
      setclientData((CRMData || []).find((obj: any) => obj.clientid === prams.id));
    }
  });
  console.log('notes', notes);

  const dataSource = () => {
    return (notes || []).map((obj: any, i: number) => {
      const { noteid, comment, leadProgress, title, addedDate } = obj;
      return {
        key: noteid,
        id: i + 1,
        comment,
        leadProgress,
        title,
        addedDate: helperFunctions.shortMonthDate(addedDate),
        clientName: `${clientData?.firstName} ${clientData?.lastName}`,
      };
    });
  };
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
    },
    {
      title: 'Lead Progress',
      dataIndex: 'leadProgress',
      key: 'leadProgress',
    },
    {
      title: 'Added Date',
      dataIndex: 'addedDate',
      key: 'addedDate',
    },

    {
      title: 'comment',
      dataIndex: 'comment',
      key: 'comment',
    },
  ];
  return (
    <Main>
      <h3>{`${clientData?.firstName} ${clientData?.lastName}`}</h3>
      <p>this is detail of all notes about {`${clientData?.firstName} ${clientData?.lastName}`}</p>
      <Table
        pagination={{
          defaultPageSize: 5,
          total: dataSource().length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        dataSource={dataSource()}
        columns={columns}
      />
    </Main>
  );
};

export default CRMHistory;
