import actions from './actions';
import reduxActionModal from '../../class/reduxActionModal';
interface User {
  loading: boolean;
  data: object | null;
  error: any;
}
const initialState: User = {
  loading: false,
  data: null,
  error: null,
};

const { COMPANY_BEGIN, COMPANY_SUCCESS, COMPANY_ERR, LOGOUT_SUCCESS } = actions;

const Reducer = (state: User = initialState, action: reduxActionModal) => {
  const { type, data, err } = action;
  switch (type) {
    case COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case COMPANY_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case COMPANY_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Reducer;
