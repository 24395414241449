import React, { FC, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import CRMModal from './CRMModal';
import MenuDataModal from '../class/MenuDataModal';
import AppIcons from '../helper/AppIcons';

const SideNavLinkGen: FC<{ obj: MenuDataModal; activeId: number }> = ({ obj, activeId }) => {
  const [dropOpen, setDropOpen] = useState<boolean>(false);
  const history = useHistory();
  return (
    <Menu.Item
      icon={AppIcons(obj.icon)}
      onClick={() => {
        setDropOpen(!dropOpen);
      }}
      onDoubleClick={() => history.push(obj.link)}
      key={obj.id}
    >
      {obj.text}
    </Menu.Item>
  );
};

export default SideNavLinkGen;
