import actions from './actions';
import API from '../../config/API';
import { getApi, postApi } from '../../helper/ApiRequest';

const { questionBegin, questionSuccess, questionErr,demoSuccess } = actions;

const AddNewQuestion = (value: {
  question: string;
  answer1: string;
  answer2: string;
  answer3: string;
  type: string;
}) => {
  return async (dispatch: any) => {
    try {
      dispatch(questionBegin());
      const response: any = await postApi(API.questions.root, value, dispatch);
      if (response.status === 200) {
        alert('question added success full');
        return true;
      } else {
        throw response;
      }
    } catch (err) {
      dispatch(questionErr(err));
      return false;
    }
  };
};
const getQuestions = (type: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(questionBegin());
      const response: any = await getApi(`${API.questions.root}/${type}`, dispatch);
      if (response.status === 200) {
        dispatch(questionSuccess(response.data));
      }
    } catch (err) {
      dispatch(questionErr(err));
    }
  };
};
const getDemoSuccess = () => {
  return async (dispatch: any) => {
    try {
      dispatch(questionBegin());
      const response: any = await getApi(`${API.questions.demo}`, dispatch);
      if (response.status === 200) {
        dispatch(demoSuccess(response.data));
      }
    } catch (err) {
      dispatch(questionErr(err));
    }
  };
};

export { AddNewQuestion, getQuestions,getDemoSuccess };
