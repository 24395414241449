import React, { useState } from 'react';
import { Switch } from 'react-router-dom';
// import MobSideNav from './MobSideNav';
import { useRouteMatch } from 'react-router-dom';
import Dashboard from './Dashboard';
import AddQuestion from '../Admin/AdminQuestions/AddQuestion';
import Users from '../comman/Users';
import AddUser from '../comman/AddUser';
import TopNavBar from '../../components/TopNavBar';
import SideNav from '../../components/SideNav';
import PrivateRoute from '../../components/PrivateRoute';

import { MainContent, MainBody, MainContentDiv } from '../styled';
import { roles } from '../../helper/enum';

function DashboardStruct() {
  const { path } = useRouteMatch();
  return (
    <MainBody>
      <TopNavBar />
      <div className="d-flex">
        <SideNav role="admin" menuId={1} />
        <MainContentDiv>
          <MainContent>
            <Switch>
              <PrivateRoute Component={Users} path={`${path}/users`} exact role={roles.admin} />
              <PrivateRoute Component={AddUser} path={`${path}/users/add`} exact role={roles.admin} />
              <PrivateRoute Component={AddQuestion} path={`${path}/addquestion`} exact role={roles.admin} />
              <PrivateRoute Component={Dashboard} path={path} exact role={roles.admin} />
            </Switch>
          </MainContent>
        </MainContentDiv>
      </div>
    </MainBody>
  );
}

export default DashboardStruct;
