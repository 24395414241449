import React from 'react';
import { Switch } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import AdminQuestions from './AdminQuestions';
import AddQuestion from './AdminQuestions/AddQuestion';
import Company from './Company';
import AddCompany from './Company/AddCompany';
import Dashboard from './Dashboard';
import SideNav from '../../components/SideNav';
import TopNavBar from '../../components/TopNavBar';
import PrivateRoute from '../../components/PrivateRoute';
import { MainContent, MainBody, MainContentDiv } from '../styled';
import { roles } from '../../helper/enum';
function Admin() {
  const { path } = useRouteMatch();

  return (
    <MainBody>
      <TopNavBar />
      <div className="d-flex">
        <SideNav role={roles.superAdmin} menuId={1} />
        <MainContentDiv>
          <MainContent>
            <Switch>
              <PrivateRoute exact Component={Dashboard} path={path} role={roles.superAdmin} />
              <PrivateRoute Component={AdminQuestions} path={`${path}/libraries`} role={roles.superAdmin} />
              <PrivateRoute Component={AddQuestion} path={`${path}/libraries/addquestion`} role={roles.superAdmin} />
              <PrivateRoute Component={Company} path={`${path}/companies`} role={roles.superAdmin} />
              <PrivateRoute Component={AddCompany} path={`${path}/companies/addNew`} role={roles.superAdmin} />
            </Switch>
          </MainContent>
        </MainContentDiv>
      </div>
    </MainBody>
  );
}

export default Admin;
