import { message } from 'antd';
import actions from './actions';
import API from '../../config/API';
import { getApi, postApi, putApi } from '../../helper/ApiRequest';
import UserModel from '../../class/userModal';

const { UserBegin, UserSuccess, UserErr } = actions;

const addUsers = (values: UserModel) => {
  return async (dispatch: any) => {
    try {
      dispatch(UserBegin());
      const result: any = await postApi(`${API.Users.root}`, values, dispatch);
      if (result.status === 200) {
        const response: any = await getApi(`${API.Users.root}`, dispatch);
        if (response.status === 200) {
          dispatch(UserSuccess(response.data));
          return true;
        }
      }
    } catch (err) {
      dispatch(UserErr(err));
      return false;
    }
  };
};
const getUsers = (companyId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(UserBegin());
      const response: any = await getApi(`${API.Users.root}/${companyId}`, dispatch);
      if (response.status === 200) {
        dispatch(UserSuccess(response.data));
      }
    } catch (err) {
      dispatch(UserErr(err));
    }
  };
};
const changeStatus = (companyId: string, status: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(UserBegin());
      const response: any = await putApi(`${API.Users.changeStatus}/${companyId}`, { status }, dispatch);
      if (response.status === 200) {
        const result: any = await getApi(`${API.Users.root}`, dispatch);
        if (result.status === 200) {
          dispatch(UserSuccess(result.data));
        }
      }
    } catch (err) {
      dispatch(UserErr(err));
    }
  };
};
const changePassword = (companyId: string, password: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(UserBegin());
      const response: any = await putApi(`${API.Users.changePassword}/${companyId}`, { password }, dispatch);
      if (response.status === 200) {
        const result: any = await getApi(`${API.Users.root}`, dispatch);
        if (result.status === 200) {
          dispatch(UserSuccess(result.data));
          message.success('password Change Success full');
          return true;
        }
      }
    } catch (err) {
      dispatch(UserErr(err));
      return false;
    }
  };
};
const getUsersforAdmin = () => {
  return async (dispatch: any) => {
    try {
      dispatch(UserBegin());
      const response: any = await getApi(`${API.Users.root}`, dispatch);
      if (response.status === 200) {
        dispatch(UserSuccess(response.data));
      }
    } catch (err) {
      dispatch(UserErr(err));
    }
  };
};

export { getUsers, addUsers, getUsersforAdmin, changeStatus, changePassword };
