import React, { FC, useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Menu, Form, Input, Select, Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormStyled, SideNavWrapper } from './styled';
// import MenuDataModal from '../class/MenuDataModal';
// import AppIcons from '../helper/AppIcons';
import { validations } from '../helper/validations';
import { addNotes, getCRM, getNotes, updateNotes } from '../redux/CRM/actionCreator';

const SideNavLink: FC<{ obj: any }> = ({ obj }) => {
  const { notes, CRMData } = useSelector((state: any) => {
    return {
      CRMData: state.CRM.data,
      notes: state.CRM.notes,
    };
  });
  console.log('CRMData', CRMData);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const [singleNote,setSingleNote]:any=useState(null)
  // const [addNote, setAddNote] = useState<boolean>(false);
  const onFinish = async (data: any) => {
    const newData = {
      clientId: data.clientId.split(' ')[2],
      title: data.title,
      leadProgress: data.leadProgress,
      comment: data.comment,
    };
    if (data.noteid) {
      const result: any = await dispatch(updateNotes(data, data.noteid));
    }
    const result: any = await dispatch(addNotes(newData));
    if (result) {
      message.success('notes add successfull');
      form.resetFields();
    }
  };

  const leadProgressTypes: any = {
    open: 'Open',
    pending: 'Pending',
    notInterested: 'Not Interested',
    closed: 'closed',
  };
  useEffect(() => {
    dispatch(getCRM());
  }, []);
  const { Option } = Select;
  return (
    <FormStyled color="dark" form={form} onFinish={onFinish} layout="vertical" className="px-2">
      <Form.Item label="Select a Client" name={'clientId'} rules={validations.simpleValidation}>
        <Select
          showSearch
          placeholder="Select a Client"
          style={{ width: '100%' }}
          onChange={(e: string) => {
            if (e) {
              form.setFieldsValue({ phone: e.split(' ')[3], email: e.split(' ')[4] });
              dispatch(getNotes(e.split(' ')[2]));
            }
          }}
        >
          {(CRMData || []).map((obj: any) => {
            return (
              <Option
                key={obj.clientid}
                value={`${obj?.firstName} ${obj?.lastName} ${obj.clientid} ${obj.phone} ${obj.email}`}
              >{`${obj?.firstName} ${obj?.lastName}`}</Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Select a Notes" name="note">
        <Select
          showSearch
          placeholder="Select a Note"
          style={{ width: '100%' }}
          filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(e: string) => {
            if (e) {
              const data: any = JSON.parse(e.split('_')[1]);
              delete data.clientId;
              form.setFieldsValue(data);
            }
          }}
        >
          {(notes || []).map((obj: any) => {
            return (
              <Option key={obj.noteid} value={`${obj.title}_${JSON.stringify(obj)}`}>
                {obj.title}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="noteid" label="noteid" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="phone" label="Ehone">
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Email">
        <Input />
      </Form.Item>
      <Form.Item name="title" label="Title" rules={validations.simpleValidation}>
        <Input />
      </Form.Item>
      <Form.Item name="leadProgress" label="Lead Progress" rules={validations.simpleValidation}>
        <Select>
          {Object.keys(leadProgressTypes || {}).map((i) => {
            return (
              <Option value={i} key={i}>
                {leadProgressTypes[i]}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="comment" label="Note" rules={validations.simpleValidation}>
        <Input.TextArea />
      </Form.Item>

      <Button type="default" className="me-2" htmlType="reset">
        Reset
      </Button>
      <Button type="primary" htmlType="submit">
        Save
      </Button>
    </FormStyled>
  );
};

export default SideNavLink;
