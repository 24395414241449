import React from 'react';
import { Button } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import CRMTable from './CRMTable';
import { Main } from '../../styled';

function Index() {
  const { path } = useRouteMatch();
  return (
    <Main>
      <div className="d-flex justify-content-between align-content-center">
        <h3>CRM</h3>
        <Button href={`${path}/add`} type="primary">
          Add New
        </Button>
      </div>
      <CRMTable />
    </Main>
  );
}

export default Index;
