import React from 'react';
import AdminQuestions from '../../Admin/AdminQuestions';
import { Main } from '../../styled';

const SettingPage = () => {
  return (
    <Main>
      <AdminQuestions />
    </Main>
  );
};

export default SettingPage;
