import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { match, StaticContext } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { roles } from '../helper/enum';

const PublicRoute: FC<
  {
    Component: FC<{
      history: any;
      location: any;
      match: match<{ [x: string]: string | undefined }>;
      staticContext?: StaticContext | undefined;
    }>;
    restricted?: boolean;
  } & Record<string, any>
> = ({ Component, restricted, ...rest }) => {
  const { isLogin } = useSelector((state: any) => {
    return {
      isLogin: state.auth.data,
    };
  });
  const routeGenrater = (type: string) => {
    switch (type) {
      case roles.superAdmin:
        return '/superAdmin';
      case roles.admin:
        return '/admin';
      case roles.employee:
        return '/user';

      default:
        return '/';
    }
  };
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      render={(props) =>
        isLogin && restricted ? <Redirect to={routeGenrater(isLogin?.data?.type)} /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
