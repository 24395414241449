import { Button, Input, Space, Table } from 'antd';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table/interface';
import { IoSearchOutline } from 'react-icons/io5';
import Highlighter from 'react-highlight-words';
import { getDemoSuccess } from '../../../redux/Question/actionCreator';
import { TableWrapper } from '../../styled';

const DemoTable = () => {
  const dispatch = useDispatch();
  const { demoData } = useSelector((state: any) => {
    return {
        demoData: state.questions.demoData,
    };
  });
  const searchInput = useRef(null);
  useEffect(() => {
    dispatch(getDemoSuccess());
  }, []);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex: any) => ({
    // eslint-disable-next-line
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:{setSelectedKeys:any, selectedKeys:any, confirm:any, clearFilters:any}) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<IoSearchOutline />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <IoSearchOutline style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          // eslint-disable-next-line react/destructuring-assignment
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns: ColumnsType<any> = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      ...getColumnSearchProps('companyName'),
    },
    {
      title: 'firstName',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a: any, b: any) => a.firstName.length - b.firstName.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'lastName',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a: any, b: any) => a.lastName.length - b.lastName.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: any, b: any) => a.email.length - b.email.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'country',
      dataIndex: 'country',
      key: 'country',
      sorter: (a: any, b: any) => a.country.length - b.country.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'employees',
      dataIndex: 'employees',
      key: 'employees',
      sorter: (a: any, b: any) => a.employees.length - b.employees.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (a: any, b: any) => a.phone.length - b.phone.length,
      sortDirections: ['descend', 'ascend'],
    },
  ];
  console.log('demoData',demoData);
  
  const candidatTableData = () => {
    return (demoData || []).map((data: any, i: number) => {
      const { id, 
        companyName,
        country,
        createdDate,
        email,
        employees,
        firstName,
        jobTitle,
        lastName,
        phone
      } = data;

      return {
        key: id,
        id: i + 1,
        companyName,
        country,
        createdDate,
        email,
        employees,
        firstName,
        jobTitle,
        lastName,
        phone
      };
    });
  };
  return (
    <TableWrapper>
      <Table
        columns={columns}
        dataSource={candidatTableData()}
        pagination={{
          defaultPageSize: 5,
          total: candidatTableData().length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </TableWrapper>
  );
  // return (
  //   <TableWrapper striped hover>
  //     <thead className=" shadow font-light">
  //       <tr>
  //         <th>#</th>
  //         <th>Question</th>
  //         <th>Answer1</th>
  //         <th>Answer2</th>
  //         <th>Answer3</th>
  //       </tr>
  //     </thead>
  //     <tbody>
  //       {(data || []).map((obj: any, i: number) => {
  //         return (
  //           <tr key={obj.Id}>
  //             <td>{i + 1}</td>
  //             <td>{obj?.question}</td>
  //             <td>{obj?.answer1}</td>
  //             <td>{obj?.answer2}</td>
  //             <td>{obj?.answer3}</td>
  //           </tr>
  //         );
  //       })}
  //     </tbody>
  //   </TableWrapper>
  // );
};

export default DemoTable;
