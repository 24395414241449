import actions from './actions';
import API from '../../config/API';
import { postApi } from '../../helper/ApiRequest';

const { loginBegin, loginSuccess, loginErr, logoutSuccess } = actions;

const login = (value: { email: string; password: string }) => {
  return async (dispatch: any) => {
    try {
      dispatch(loginBegin());

      const response: any = await postApi(API.auth.login, value, dispatch);
      if (response.status === 200) {
        localStorage.setItem('logedIn', JSON.stringify(response.data));
        dispatch(loginSuccess(response.data));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};
const logout = () => {
  return async (dispatch: any) => {
    try {
      dispatch(loginBegin());
      localStorage.removeItem('logedIn');
      // await postApi(API.auth.logout, {}, dispatch);

      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

export { login, logout };
