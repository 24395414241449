import { Button } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddNewQuestion } from '../../../redux/Question/actionCreator';

const AddQuestion = () => {
  const dispatch = useDispatch();
  const { questionTypes } = useSelector((state: any) => {
    return {
      questionTypes: state.questions.types,
    };
  });
  const submitFunction = async (e: any) => {
    e.preventDefault();
    const result: any = await dispatch(
      AddNewQuestion({
        question: e.target.question.value,
        answer1: e.target.answer1.value,
        answer2: e.target.answer2.value,
        answer3: e.target.answer3.value,
        type: e.target.type.value,
      }),
    );
    if (result) {
      e.target.reset();
    }
  };
  return (
    <div className="container">
      <h2 className="text-center mb-4 mt-3">Add Questions</h2>
      <form className="loginForm" onSubmit={submitFunction}>
        <div className="form-group mb-3">
          <label htmlFor="question" className="d-none d-md-block font-dark">
            Question
          </label>
          <input type="text" required name="question" className="form-control bg-transparent1" placeholder="Question" />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="anwer1" className="d-none d-md-block font-dark">
            Answer1
          </label>
          <input type="text" required name="answer1" className="form-control bg-transparent1" placeholder="answer" />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="anwer2" className="d-none d-md-block font-dark">
            Answer2
          </label>
          <input type="text" required name="answer2" className="form-control bg-transparent1" placeholder="answer" />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="anwer3" className="d-none d-md-block font-dark">
            Answer3
          </label>
          <input type="text" required name="answer3" className="form-control bg-transparent1" placeholder="answer" />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="anwer1" className="d-none d-md-block font-dark">
            Type
          </label>
          <select required name="type" className="form-control bg-transparent1" placeholder="answer">
            <option>--select--</option>
            {questionTypes.map((obj: string) => {
              return (
                <option key={obj} value={obj}>
                  {obj}
                </option>
              );
            })}
          </select>
        </div>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

export default AddQuestion;
