import React, { useState } from 'react';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import QuestionTable from './QuestionTable';
import { QuestionWrapper } from '../styled';
const AdminQuestions = () => {
  const { path } = useRouteMatch();
  const { questionTypes } = useSelector((state: any) => {
    return {
      questionTypes: state.questions.types,
    };
  });
  const [setTab, setsetTab] = useState('tonal patterns');
  return (
    <div className="container-fluid">
      <h2 className="font-dark text-center mb-4">Questions</h2>
      <h2 className="font-dark text-end mb-4">
        <Link to={`${path}/addquestion`} className="btn btn-primary">
          Add new
        </Link>
      </h2>
      <QuestionWrapper>
        <Tab.Container id="left-tabs-example" defaultActiveKey="tonal patterns">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                {(questionTypes || []).map((obj: string) => {
                  return (
                    <Nav.Item key={obj}>
                      <Nav.Link eventKey={obj} onClick={() => setsetTab(obj)}>
                        {obj}
                      </Nav.Link>
                    </Nav.Item>
                  );
                })}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                {(questionTypes || []).map((obj: string) => {
                  return (
                    <Tab.Pane key={obj} eventKey={obj}>
                      {setTab === obj ? <QuestionTable type={obj} /> : <></>}
                    </Tab.Pane>
                  );
                })}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </QuestionWrapper>
    </div>
  );
};

export default AdminQuestions;
