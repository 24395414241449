import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { useParams } from 'react-router';
import UserTable from './UserTable';
import { getUsers } from '../../../redux/Users/actionCreator';
import { Main } from '../../styled';

const Users = () => {
  const prams: any = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers(prams.companyId));
  }, []);
  return (
    <Main>
      <div className="d-flex justify-content-between align-content-center">
        <h3>Users</h3>
        <Button href="/admin/users/add" type="primary">
          Add New
        </Button>
      </div>
      <UserTable />
    </Main>
  );
};

export default Users;
