import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { verifyPaymentClient } from '../../redux/Company/actionCreator';

const Success: FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  console.log(queryParams.get('payment_intent'));
  console.log(queryParams.get('payment_intent_client_secret'));
  const dispatch = useDispatch();
  //   successPayment
  useEffect(() => {
    dispatch(
      verifyPaymentClient({
        payment_intent: queryParams.get('payment_intent'),
        payment_intent_client_secret: queryParams.get('payment_intent_client_secret'),
      }),
    );
  }, []);
  return <div>Successfull payment</div>;
};

export default Success;
