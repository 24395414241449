import axios from 'axios';
import API from '../config/API';
import ENV from '../config/ENV/env';
import actions from '../redux/Auth/actions';

export default (dispatch: any) => {
  const logoutSuccess: (data: null) => {} = actions.logoutSuccess;
  const baseURL = 'https://portal.affectiveai.com/api';
  const headers: any = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  };
  const logedIn = JSON.parse(localStorage.getItem('logedIn') || 'null');
  if (logedIn) {
    headers.Authorization = `Bearer ${logedIn.token}`;
  }

  const axiosInstance = axios.create({
    baseURL,
    headers,
    timeout: 10000,
  });

  const logout = async () => {
    try {
      await axios.post(`${baseURL}${API.auth.logout}`);
      localStorage.removeItem('logedIn');
      if (dispatch) {
        dispatch(logoutSuccess(null));
      }
    } catch (error) {
      localStorage.removeItem('logedIn');
      if (dispatch) {
        dispatch(logoutSuccess(null));
      }
    }
  };
  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve) => {
        resolve(response);
      }),
    (err) => {
      if (!err.response) {
        logout();
        return new Promise((resolve, reject) => {
          reject(err);
        });
      }
      if (err.response.status === 401) {
        logout();
        return new Promise((resolve, reject) => {
          reject(err);
        });
      }
      return new Promise((resolve, reject) => {
        reject(err);
      });
    },
  );
  return axiosInstance;
};
