import actions from './actions';
import reduxActionModal from '../../class/reduxActionModal';
import { questionTypes } from '../../helper/enum';
interface Payment {
  plans: [];
  loading: boolean;
  data: object | null;
  error: any;
}
const initialState: Payment = {
  plans: [],
  loading: false,
  data: null,
  error: null,
};

const { PAYMENT_BEGIN, PAYMENT_SUCCESS, PAYMENT_ERR, LOGOUT_SUCCESS, PLANS_BEGIN, PLANS_SUCCESS, PLANS_ERR } = actions;

const Reducer = (state: Payment = initialState, action: reduxActionModal) => {
  const { type, data, err } = action;
  switch (type) {
    case PLANS_SUCCESS:
      return {
        ...state,
        plans: data,
        loading: false,
      };
    case PLANS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case PLANS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PAYMENT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case PAYMENT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Reducer;
