import actions from './actions';
import reduxActionModal from '../../class/reduxActionModal';
interface Auth {
  loading: boolean;
  data: object | null;
  error: any;
}
const initialState: Auth = {
  loading: false,
  data: JSON.parse(localStorage.getItem('logedIn') || 'null'),
  error: null,
};

const { LOGIN_BEGIN, LOGIN_SUCCESS, LOGIN_ERR, LOGOUT_SUCCESS } = actions;

const Reducer = (state: Auth = initialState, action: reduxActionModal) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        data: null,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default Reducer;
