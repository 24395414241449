import Styled from 'styled-components';
import { Form } from 'antd';

const SwitchWrapper = Styled.label`

    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  
   input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.dark};
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: ${(props) => props.theme.primary};
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: ${(props) => props.theme.light};
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

`;
const TopNavWrapper = Styled.nav`
color:${(props) => props.theme.light};
background:${(props) => props.theme.primary};
box-shadow:10px 5px 10px 0px ${(props) => props.theme.antiprimary}1f;
position:relative;
z-index:5;
&>div{
  height:53px;
}
svg{
  // color:${(props) => props.theme.white} !important;
  
}
.liailogo {
  height : 50px;
  filter: invert(1);
}
.liailogo-light {
  height : 50px;
  // filter: invert(1);
}
@media only screen and (min-width:280px) and (max-width:400px){
  .liailogo {
   margin-left:-20px;
  }
  .liailogo-light {
    margin-left:-20px;
  }
}
`;
const SideNavWrapper = Styled.nav`
ul{
  height:100%;
}
`;
const FormStyled = Styled(Form)`
.ant-form-item-label{
  label{
    color:${(props) => props.theme.dark};
  }
}
`;

const NavBarWrapper = Styled.nav`
  display: flex;
  position: fixed;
  z-index: 999;
  left: 0px;
  top: 0px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme['dark-color']}10;
  padding: 0.0125rem 0.5rem;
  transition: all 1s ease;
  &.has-scrolled {
    background: ${({ theme }) => theme['dark-color']};
  }
  .brandlogo {
    padding: 1rem;
    @media only screen and (min-width: 991px) {
      display: none;
    }
    img {
      width: 50px;
      // filter: invert(1);
    }
  }
  .collapsAbleMenu {
    .brandlogo {
      padding: 0.5rem;
      @media only screen and (min-width: 991px) {
        display: block;
      }
      @media only screen and (max-width: 991px) {
        display: none;
      }
      img {
        padding: 0.5rem 0.25rem;
        width: 80px;
        // filter: invert(1);
      }
    }
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: stretch;
    .menuOpener {
      background-color: ${({ theme }) => theme['dark-color']}00;
      color: ${({ theme }) => theme['light-hover']};
      padding: 0.5rem 1.5rem;
      border: none;
      display: none;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 600;
      margin-left: auto;
      @media only screen and (max-width: 991px) {
        display: flex !important;
      }
      svg {
        margin-left: 1rem;
      }
      &:hover {
        background-color: ${({ theme }) => theme['dark-hover']}91;
        color: ${({ theme }) => theme['light-hover']};
      }
    }
    .menuList {
      list-style-type: none;
      display: flex;
      margin: 0px;
      padding-left: 0px;
      padding-right: 0;
      @media only screen and (max-width: 991px) {
        display: none;
      }
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        a,button {
          text-decoration: none;
          font-weight: 600;
          padding: 1rem 1rem;
          margin: auto 0.25rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          color: white;
          overflow: hidden;
          background:transparent;
          border:none !important;
          &:hover {
            color: ${({ theme }) => theme['primary-color']};
            &::after {
              transform: translateX(0%);
              transition: transform 0.3s ease;
            }
          }
          &.animate-out {
            color: ${({ theme }) => theme['primary-color']};
            &::after {
              transform: translateX(100%);
              transition: transform 0.3s ease;
            }
          }
          &::after {
            transform: translateX(-101%);
            position: absolute;
            content: '';
            bottom: 0px;
            height: 3px;
            width: 100%;
            background: currentColor;
          }
        }
      }
    }
  }
`;
const HeaderWrapper = Styled.header`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // mask-image:url('../assets/img/mQ9mJOcwMI4msIG-Brush-Texture-PNG-Free-Download (2).png');
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content {
    z-index: 10;
    font-size: 3rem;
    color: white;
  }
  .mask {
    // mix-blend-mode: multiply;
    z-index: 20;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    color: #08162e;
    img {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
const HeaderWrapper2 = Styled.header`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .zindex-5 {
    z-index: 10;
  }
  .content {
    padding: 20px;
    z-index: 10;
    font-size: 3rem;
    color: white;

    @media (max-width: 1200px) {
      padding: 20px;
    }
    @media (max-width: 991px) {
      padding: 15px;
    }

    h3 {
      color: white;
      font-size: 2.5rem;
      font-family: 'Gotham';
      font-weight: 900;
      letter-spacing: 2.5px;
      margin-bottom:30px;
      @media (max-width: 1200px) {
        font-size: 2.25rem;
      }
      @media (max-width: 768px) {
        font-size: 2rem;
      }
    }
    p {
      font-size: 1.5rem;
      @media (max-width: 991px) {
        font-size: 1.25rem;
      }
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }
  &:before {
    position: absolute;
    content: '';
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme['dark-color']}7d;
    z-index: 1;
  }
  .btncustomLight {
    margin: 1rem;
    border-radius: 12px;
    font-family: Gotham;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    width: 100%;
    line-height: 1;
    height: 48px;
    &.btn-dark {
      background: ${({ theme }) => theme['black']};
    }
  }
`;
const MakeChangesWrapper = Styled.section`
  position: relative;
  min-height: 750px;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 3rem 0px;
  margin: 2rem 0px;
  flex-direction: column;

  .content {
    min-height: 450px;
    position: relative;
    text-align: center;
    padding: 200px 0px;
    h6 {
      font-size: 1.5rem;
    }
    .text {
      max-width: 940px;
      margin: auto;
    }
    .subHeading {
      color: ${({ theme }) => theme['dark-color']};
      font-size: 5rem;
      font-weight: 900;
      margin-top: 3rem;
      margin-bottom: 150px;
      line-height: 1;
      @media (max-width: 991px) {
        font-size: 2rem;
        margin-bottom: 3rem;
      }
    }
    p {
      margin-bottom: 2rem;
      font-size: 1.5rem;
      font-weight: 500;
      @media (max-width: 991px) {
        font-size: 1.25rem;
      }
    }
    .pointsContainer {
      display: flex;
      justify-content: space-between;
      position: relative;
      flex-wrap: wrap;
      .points {
        margin: 0.5rem auto;
        position: relative;
        padding: 85px;
        .circle {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: ${({ theme }) => theme['primary-color']};
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${({ theme }) => theme['white-color']};
          transition: all 0.2s 0s ease-in-out;
          &:hover {
            font-size: 1rem;
            width: 120%;
            height: 120%;
          }
        }
      }
    }
  }
`;
const NavMenuWrapper = Styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  bottom: 0px;
  right: 0px;
  transform: translate(-100%, 0px) rotateY(60deg) scale(0.5, 0.5);
  z-index: 999;
  background-color: ${({ theme }) => theme['dark-hover']}eb;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    width: 40px;
    height: 40px;
    // border:1px solid ${({ theme }) => theme['light-hover']};
    border: none;
    border-radius: 5px;
    color: ${({ theme }) => theme['light-hover']};
    cursor: pointer;
    content: '';

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(45deg);
      content: '';
      width: 30px;
      height: 3px;
      background: ${({ theme }) => theme['light-hover']};
      transition: all 0.3s ease 0.5s;
    }
    &::after {
      transition: all 0.3s ease;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotateZ(-45deg);
      content: '';
      width: 30px;
      height: 3px;
      background: ${({ theme }) => theme['light-hover']};
      transition: all 0.3s ease 0.5s;
    }
    &.closed {
      &::before,
      &::after {
        transform: translate(-50%, -50%);
      }
    }
  }
  ul {
    text-align: center;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    li {
      a {
        min-height: 55px;
        display: block;
        color: ${({ theme }) => theme['light-hover']};
        text-decoration: none;
        font-weight: 700;
        font-size: 2rem;
        transition: all 0.3s ease;
        &:hover {
          color: ${({ theme }) => theme['light-color']};
          font-size: 2.25rem;
        }
      }
    }
  }
`;
const GeetingStartWrapper = Styled.section`
  background-color: ${({ theme }) => theme['dark-hover']};
  h3 {
    color: white;
  }
`;
const MyCard = Styled.div`
  width: 100%;
  min-height: 300px;
  padding: 4rem 2rem;
  border-radius: 10px;
  background: ${({ theme }) => theme['white']};
  border: 1px solid ${({ theme }) => theme['light-color']}50;
  box-shadow: 5px 5px 20px ${({ theme }) => theme['light-color']}40;
  text-align: center;
  font-size: 1rem;
  transition: all 0.25s ease-in;
  .btn {
    background: ${({ theme }) => theme['light-hover']};
  }
  &:hover {
    background: ${({ theme }) => theme['light-hover']};
    border: 1px solid ${({ theme }) => theme['light-color']}80;
    box-shadow: 5px 5px 20px ${({ theme }) => theme['light-color']}80;
    color: black;
    .btn {
      transition: all 0.5s ease-in;
      background: ${({ theme }) => theme['dark-hover']};
      color: ${({ theme }) => theme['white']};
    }
  }
  .title {
    color: rgba(132, 147, 173.1);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .tag {
    font-weight: 700;
    font-size: 1.875rem;
    margin-bottom: 2rem;
  }

  ul {
    list-style-type: disclosure-closed;
    text-align: left;
    li {
      margin: 0.25rem auto;
    }
  }
`;
const PartnerSection = Styled.div`
  padding: 50px 0px 350px;
  margin-top: 50px;
  position: relative;
  .soupimg {
    max-width: 80vw;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
  .circleimg {
    max-width: 10vw;
    width: 100%;
    position: absolute;
    z-index: 1;
    left: 10vw;
    top: 0px;
  }
  .container {
    position: relative;
    z-index: 3;
  }
  .contentHeading {
    margin-bottom: 150px;
  }
  .CustomSlide {
    padding: 1rem;
    img {
      max-width: 150px;
      width: 100%;
      filter: grayscale(1);
    }
  }
`;
const FooterWrapper = Styled.div`
  .socialConnect {
    background: ${({ theme }) => theme['primary-color']};
    color: white;
    padding: 3rem 0px 3rem;
    .container {
      .containerCenter {
        min-height: 400px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .contentHeading {
        color: white;
      }
      h1 {
        margin-bottom: 3rem;
        color: white;
        font-weight: 900;
      }
      .social-Icons {
        display: flex;
        list-style-type: none;
        width: 100%;
        justify-content: space-around;
        margin-top: 3rem;
        fle-wrap: wrap;
        margin-bottom: 70px;
        li {
          a {
            text-decoration: none;
            color: ${({ theme }) => theme['primary-color']};
            background-color: ${({ theme }) => theme['white-color']};
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  .footer {
    padding: 3rem 0px 0rem;
    z-index: 1;
    margin-top: 4rem;
    position: relative;
    grid-area: footer;
    &::before {
      content: '';
      position: absolute;
      width: 80%;
      height: 3px;
      left: 50%;
      transform: translateX(-50%);
      background: ${({ theme }) => theme['dark-color']};
    }
    .content {
      z-index: 2;
      padding-top: 2rem;
      background: var(--footer-background);

      footer {
        .main-footer {
          padding: 20px 0;
          min-height: 12rem;
          position: relative;
          overflow: hidden;

          .circleimg {
            max-width: 10vw;
            width: 100%;
            position: absolute;
            z-index: 1;
            left: 15vw;
            top: 100px;
          }

          .circleimg2 {
            max-width: 7vw;
            width: 100%;
            position: absolute;
            z-index: 1;
            left: 7vw;
            top: 250px;
          }
          .container {
            position: relative;
            z-index: 3;
          }
          .FooterLogo {
            max-width: 120px;
            width: 100%;
            margin-top: 3rem;
            margin-bottom: 250px;
            filter: invert(1);
          }
        }
        .footer-copyright {
          padding: 0.5rem 0px;
          background-color: #c8c8c8;
        }
      }

      ul {
        &.social-footer2 {
          padding: 0;
          width: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          max-width: 600px;
          list-style-type: none;
          li {
            padding: 0;
            a {
              display: block;
              text-align: center;
              background-color: ${({ theme }) => theme['primary-color']};
              color: ${({ theme }) => theme['white']};
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50px;
              padding: 0.75rem;
              &:hover {
                box-shadow: 0px 0px 20px ${({ theme }) => theme['dark-color']}20;
              }
              svg {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }
    }
  }
`;
const FAQsWrapper = Styled.section`
  background-color: ${({ theme }) => theme['light-color']};
  .ant-collapse-item {
    .ant-collapse-header {
      display: flex;
      align-items: center !important;
      background-color: ${({ theme }) => theme['primary-color']};
      color: ${({ theme }) => theme['white']};
      transition: all 0.5s ease-in;
      font-size: 1rem;
      font-weight: 700;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &.ant-collapse-item-active {
      .ant-collapse-header {
        color: ${({ theme }) => theme['dark-color']};
        background-color: ${({ theme }) => theme['white']};
      }
    }
  }
`;
const IntroductionWrapper = Styled.section`
  background-color: ${({ theme }) => theme['primary-color']};
  color: ${({ theme }) => theme['white']};
  padding: 150px 0rem;
  .IntroVideoWrapper {
    position: relative;
    margin: 3rem auto;
    max-width: 600px;
    width: 100%;
    border-radius: 50px;
    overflow: hidden;
    background-color: ${({ theme }) => theme['dark-color']};
    .playBtn {
      border: 0px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100px;
      width: 100px;
      background-color: ${({ theme }) => theme['primary-color']};
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 5px 5px 20px #ffffff14, -5px -5px 20px #00000033;
      &::before {
        content: '';
        position: relative;
        width: 0px;
        height: 0px;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 25px solid white;
        margin-left: 12px;
      }
    }

    #IntroVideo {
      width: 100%;
      // border-radius:50px;
      // position:absolute;
      // left:0px;
      // top:0px;
    }
  }
  h6 {
    text-align: center;
    color: ${({ theme }) => theme['white']};
    margin-bottom: 3.5rem;
    line-height: 1;
    font-size: 1.5rem;
    @media (max-width: 991px) {
      font-size: 1.25rem;
    }
  }
  h1 {
    text-align: center;
    color: ${({ theme }) => theme['white']};
    margin-bottom: 1.5rem;
    font-size: 3rem;
    max-width: 450px;
    margin: 5rem auto;
    line-height: 1;
    font-weight: 700;
    @media (max-width: 991px) {
      font-size: 2.5rem;
    }
  }
`;
const BenefitsWrapper = Styled.section`
  padding: 3rem 0rem 350px;
  position: relative;
  .ringimg {
    max-width: min(50vw,650px);
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 150px;
    left: -30px;
  }
  .circleimg {
    max-width: 10vw;
    width: 100%;
    position: absolute;
    z-index: 1;
    right: 50px;
    top: 300px;
  }
  .container {
    position: relative;
    z-index: 3;
  }
  @media (max-width: 991px) {
    padding: 3rem 0rem 150px;
  }
  .mainHeading {
    font-size: 4rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    width: 95%;
    margin: 3.5rem auto 8.5rem;
    line-height: 1;
    @media (max-width: 991px) {
      margin: 2rem auto 5rem;
      font-size: 2rem;
      width: 100%;
    }
  }
  .benifitsCards {
    display: flex;
    background: #f5f7fa;
    height: 290px;
    min-height: 270px;
    padding: 24px 32px 74px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #d4d4d4;
    border-radius: 20px;
    transition: 0.3s;
    margin-bottom: 1rem;
    position: relative;
    &:hover {
      border-color: #fff;
      background-color: #fff;
      box-shadow: 0px 0px 20px ${({ theme }) => theme['dark-color']}20;
    }
    .cardTitle {
      font-size: 1.125rem;
      font-weight: 400;
    }
    .icon {
      position: absolute;
      bottom: 20px;
      right: 20px;
      img {
        width: 80px;
        height: 80px;
      }
    }
  }
`;
const IndustryWrapper = Styled.section`
  padding: 3rem 0rem;
  position: relative;
  .btn-flex {
    display: flex;
    .btncustomLight {
      margin: 1rem;
      border-radius: 12px;
      font-family: Gotham;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 300px;
      width: 100%;
      line-height: 1;
      height: 48px;
      &.btn-dark {
        background: ${({ theme }) => theme['black']};
      }
    }
  }
  .cubeimg {
    max-width: 50vw;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 150px;
    left: -30px;
  }
  .circleimg1 {
    max-width: 10vw;
    width: 100%;
    position: absolute;
    z-index: 1;
    right: 50px;
    top: 100px;
  }
  .circleimg2 {
    max-width: 15vw;
    width: 100%;
    position: absolute;
    z-index: 1;
    right: 150px;
    top: 200px;
  }
  .container {
    position: relative;
    z-index: 3;
  }
  .subHeading {
    text-align: center;
    color: ${({ theme }) => theme['dark-color']};
    font-size: 2rem;
    max-width: 800px;
    margin: 100px auto;
    line-height: 1;
    font-weight: 900;
    @media (max-width: 991px) {
      margin: 75px auto;
      font-size: 1.75rem;
    }
  }
  .mainHeading {
    text-align: center;
    color: ${({ theme }) => theme['dark-color']};
    font-size: 3rem;
    max-width: 90%;
    margin: 100px auto;
    line-height: 1;
    font-weight: 700;
    width: 100%;
    font-weight: 900;
    @media (max-width: 991px) {
      max-width: 100%;
      margin: 75px auto;
      font-size: 2.5rem;
    }
  }
  .ant-tabs {
    .tabContainer {
      padding: 0px 30px;
      max-width: 900px;
      h3 {
        padding-top:10px;
        font-size: 2.4rem;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0.05rem;
        text-transform: capitalize;
        @media (max-width: 991px) {
          font-size: 1.75rem;
        }
      }
      p {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.7;
        letter-spacing: 0.05rem;
      }

      @media (max-width: 991px) {
        padding: 0px 15px;
      }
    }
    &.ant-tabs-left {
      .ant-tabs-nav {
        .ant-tabs-tab {
          padding: 8px 24px 8px 40px;
          @media (max-width: 768px) {
            padding: 8px 24px 8px 24px;
          }
          &:hover {
            color: ${({ theme }) => theme['primary-hover']};
          }
          .ant-tabs-tab-btn {
            font-weight: bold;
            font-size: 1.25rem;
          }
          &.ant-tabs-tab-active {
            color: ${({ theme }) => theme['primary-color']};
            .ant-tabs-tab-btn {
              color: currentColor;
            }
            &:before {
              position: absolute;
              content: '\\2192';
              left: 15px;
              top: 50%;
              line-height:25px;
              padding-bottom:4px;
              color: currentColor;
              transform: translate(-50%, -50%);
              font-size: 2rem;
              padding-top: 0px;
              @media (max-width: 768px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
`;
const LearningWrapper = Styled.section`
  padding: 100px 0rem;
  position: relative;
  .ringimg {
    max-width: min(70vw,900px);
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 50%;
    transform: translateX(-50%) scaleX(-1);
  }
  .circleimg {
    max-width: 10vw;
    width: 100%;
    position: absolute;
    z-index: 1;
    right: 50px;
    bottom: 100px;
  }
  .container {
    position: relative;
    z-index: 3;
  }
  .mainHeading {
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
    width: 100%;
    max-width: 1178px;
    margin: 5rem auto 100px;
    @media (max-width: 991px) {
      margin: 3rem auto 3rem;
      font-size: 2rem;
    }
  }
  .myGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 300px auto;
    grid-auto-flow: dense;
    grid-gap: 100px;
    @media (max-width: 1400px) {
      grid-auto-rows: 400px auto;
    }
    @media (max-width: 1200px) {
      grid-gap: 75px;
    }
    @media (max-width: 991px) {
      grid-auto-rows: 400px auto;
      grid-gap: 50px;
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 30px;
    }
  }
  .LearningCards {
    display: flex;
    // height: 100%;
    min-height: 240px;
    padding: 24px 32px 74px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #d4d4d4;
    border-radius: 30px;
    transition: 0.3s;
    background: #f5f7fa;
    position: relative;
    &:hover {
      border-color: #fff;
      background-color: #fff;
      box-shadow: 0px 0px 20px ${({ theme }) => theme['dark-color']}20;
    }
    &.long {
      grid-row: span 2;
      min-height: 600px;
      @media (max-width: 768px) {
        grid-row: span 1;
        min-height: 300px;
      }
    }
    &.short {
      grid-row: span 1;
      min-height: 300px;
    }
    .cardTitle {
      font-size: 1.125rem;
    }
    button {
      position: absolute;
      display:none;
      bottom: 20px;
      right: 20px;
      padding-top: 0px;
      padding-bottom: 0px;
      svg {
        width: 50px;
        height: 50px;
      }
    }
  }
`;
const NewsWrapper = Styled.section`
  padding: 200px 0rem;
  position: relative;
  overflow: hidden;
  .cubeimg {
    max-width: 50vw;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0px;
    right: 10vw;
  }
  .circleimg {
    max-width: 10vw;
    width: 100%;
    position: absolute;
    z-index: 1;
    left: 50px;
    bottom: 500px;
  }
  .container {
    position: relative;
    z-index: 3;
  }
  .newsContainer {
    .date {
      color: ${({ theme }) => theme['primary-color']};
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 0.75rem;
    }
    .heading {
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }
  }
  .newsImg {
    max-width: 450px;
    width: 100%;
    object-fit: cover;
  }
`;
export {
  IntroductionWrapper,
  IndustryWrapper,
  NewsWrapper,
  LearningWrapper,
  HeaderWrapper,
  BenefitsWrapper,
  PartnerSection,
  MakeChangesWrapper,
  FAQsWrapper,
  FooterWrapper,
  HeaderWrapper2,
  NavBarWrapper,
  NavMenuWrapper,
  GeetingStartWrapper,
  MyCard,
  SwitchWrapper,
  FormStyled,
  TopNavWrapper,
  SideNavWrapper,
};
