import { combineReducers } from 'redux';
import ChangeLayoutMode from './Layout/reducers';
import auth from './Auth/reducers';
import questions from './Question/reducers';
import users from './Users/reducers';
import company from './Company/reducers';
import CRM from './CRM/reducers';
import Payment from './Payment/reducers';
import SocketIo from './SocketIo/reducers';

const rootReducers = combineReducers({
  ChangeLayoutMode,
  auth,
  questions,
  users,
  company,
  CRM,
  SocketIo,
  Payment,
});
export default rootReducers;
