import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getCRM } from '../../../redux/CRM/actionCreator';
import { TableWrapper } from '../../styled';

const CRMTable = () => {
  const { CRMData } = useSelector((state: any) => {
    return {
      CRMData: state.CRM.data,
    };
  });
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCRM());
  }, []);
  const dataSource = () => {
    return (CRMData || []).map((obj: any, i: number) => {
      const { clientid, firstName, lastName, phone, type, email, leadProgress, lastDateConnected } = obj;
      return {
        key: clientid,
        id: i + 1,
        firstName,
        lastName,
        phone,
        email,
        type,
        leadProgress,
        lastDateConnected,
      };
    });
  };
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },

    {
      title: 'Last Date Connected',
      dataIndex: 'lastDateConnected',
      key: 'lastDateConnected',
    },
  ];

  return (
    <TableWrapper>
      <Table
        style={{ cursor: 'pointer' }}
        pagination={{
          defaultPageSize: 5,
          total: dataSource().length,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        onRow={(e: any) => ({
          onClick: () => {
            history.push(`${path}/history/${e.key}`);
          },
        })}
        dataSource={dataSource()}
        columns={columns}
      />
    </TableWrapper>
  );
};

export default CRMTable;
