import gsap from 'gsap';
import { FiLogIn } from 'react-icons/fi';
import { NavMenuWrapper } from './styled';

const NavMenu = () => {
  const closeMenu = () => {
    const closeBtn = document.querySelector('#mainMenu>.close') as HTMLButtonElement;
    closeBtn.classList.add('closed');
    gsap.to('#mainMenu', {
      x: '-100%',
      ease: 'power4.out',
      duration: '1.5',
      rotateY: 60,
      scale: 0.5,
    });
  };
  return (
    <NavMenuWrapper id="mainMenu">
      <button
        type="button"
        className="close"
        onClick={() => {
          closeMenu();
        }}
      >
        &nbsp;
      </button>
      <ul>
        <li>
          <a href="!#">Home</a>
        </li>
        <li>
          <a href="!#">Product</a>
        </li>
        <li>
          <a href="!#">Industry</a>
        </li>
        <li>
          <a href="!#">Learning</a>
        </li>
        <li>
          <a href="!#">Partners</a>
        </li>
        <li>
          <a href="!#">News</a>
        </li>
        <li>
          <a href="!#">Contact US</a>
        </li>
        <li>
          <a href="/login">
            Login
            <FiLogIn className="ms-2" />
          </a>
        </li>
      </ul>
    </NavMenuWrapper>
  );
};

export default NavMenu;
