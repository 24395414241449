import { FC, useEffect } from 'react';
import { RiMenuFill } from 'react-icons/ri';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FiLogIn } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import NavMenu from './NavMenu';
import { NavBarWrapper } from './styled';
import logo from '../assets/img/New logo concept.svg';

const TopNav: FC<{ dark?: boolean }> = ({ dark }) => {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    const menuList = gsap.utils.toArray('.menuList>li>a') as HTMLAnchorElement[];
    menuList.forEach((link) => {
      link.addEventListener('mouseleave', () => {
        link.classList.add('animate-out');
        setTimeout(() => {
          link.classList.remove('animate-out');
        }, 300);
      });
    });
    ScrollTrigger.create({
      start: window.innerHeight,
      end: 'bottom bottom-=20000',
      toggleClass: {
        targets: '#mainTopNav',
        className: 'has-scrolled',
      },
    });
  });
  const scrollDownOnNav = (id: string) => {
    const scrollfromTop = Number(document.getElementById(id)?.offsetTop);
    window.scrollTo(0, scrollfromTop);
  };
  return (
    <>
      <NavBarWrapper id="mainTopNav" className={dark ? 'has-scrolled' : ''}>
        <a href="/" className="brandlogo">
          <img src={logo} alt="logo" />
        </a>
        <div className="collapsAbleMenu">
          <a href="/" className="brandlogo">
            <img src={logo} alt="logo" />
          </a>

          <div className="menu-container d-flex" style={{ alignItems: 'center' }}>
            <ul className="menuList">
              <li>
                <button type="button" onClick={() => scrollDownOnNav('homeSection')}>
                  Home
                </button>
              </li>
              <li>
                <button type="button" onClick={() => scrollDownOnNav('makeChangesSection')}>
                  Product
                </button>
              </li>
              <li>
                <button type="button" onClick={() => scrollDownOnNav('industrySection')}>
                  Industry
                </button>
              </li>
              <li>
                <button type="button" onClick={() => scrollDownOnNav('learningSection')}>
                  Learning
                </button>
              </li>
              <li>
                <button type="button" onClick={() => scrollDownOnNav('newsSection')}>
                  News
                </button>
              </li>
              <li>
                <button type="button" onClick={() => scrollDownOnNav('socialSection')}>
                  Contact Us
                </button>
              </li>
            </ul>
            <Link to={'/join-waitlist'}>
              <button className="btn btn-primary text-white" style={{ marginLeft: 30 }}>
                Join Waitlist
              </button>
            </Link>
          </div>
          <ul className="menuList ">
            <li>
              <Link to="/login" className=" border-end">
                Login
                <FiLogIn className="ms-2" style={{ width: '80%' }} />
              </Link>
            </li>
            <li>
              <Link to="/register">Try It</Link>
            </li>
          </ul>
          <button
            type="button"
            className="menuOpener"
            onClick={() => {
              (document.querySelector('#mainMenu>.close') as HTMLButtonElement).classList.remove('closed');
              gsap.to('#mainMenu', {
                x: '0%',
                ease: 'power4.out',
                duration: '1.5',
                rotateY: 0,
                scale: 1,
              });
            }}
          >
            More
            <RiMenuFill />
          </button>
        </div>
      </NavBarWrapper>
      <NavMenu />
    </>
  );
};

export default TopNav;
