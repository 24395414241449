import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Input, Menu, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany } from '../../../redux/Company/actionCreator';
import { changePassword, changeStatus, getUsersforAdmin } from '../../../redux/Users/actionCreator';
import AppIcons from '../../../helper/AppIcons';
import { userStatus } from '../../../helper/enum';
import { TableWrapper } from '../../styled';
import { validations } from '../../../helper/validations';
const CompanyTable = () => {
  const { company, users } = useSelector((state: any) => {
    return {
      company: state.company.data,
      users: state.users.data,
    };
  });

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [modelVisiable, setModelVisiable] = useState(false);
  const [modelData, setModelData]: any = useState({});
  useEffect(() => {
    dispatch(getCompany());
    dispatch(getUsersforAdmin());
  }, []);

  const expandedRowRender = (companyData: any) => {
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Type of User',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
        key: 'createdDate',
      },
      {
        title: 'Last Password reset Date',
        dataIndex: 'passResetDate',
        key: 'passResetDate',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
      },
    ];
    const userDataSource = () => {
      return (users || [])
        .filter((obj: any) => obj.companyId === companyData.key)
        .map((obj: any, i: number) => {
          const { id, firstName, lastName, status, type, email, createdDate, phone, passResetDate } = obj;
          return {
            key: id,
            id: i + 1,
            firstName,
            lastName,
            status,
            email,
            type,
            createdDate,
            passResetDate,
            phone,
            action: (
              <div className="table-actions">
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item key="1" onClick={() => dispatch(changeStatus(id, userStatus.active))}>
                        {userStatus.active}
                      </Menu.Item>
                      <Menu.Item key="2" onClick={() => dispatch(changeStatus(id, userStatus.deactive))}>
                        {userStatus.deactive}
                      </Menu.Item>
                      <Menu.Item key="3" onClick={() => dispatch(changeStatus(id, userStatus.suspend))}>
                        {userStatus.suspend}
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button type="ghost" shape="circle" className="ant-btn-primary">
                    {AppIcons('menudots')}
                  </Button>
                </Dropdown>
                <Button
                  type="ghost"
                  shape="circle"
                  className="ant-btn-primary"
                  onClick={() => {
                    setModelVisiable(!modelVisiable);
                    setModelData(obj);
                  }}
                >
                  {AppIcons('eye')}
                </Button>
              </div>
            ),
          };
        });
    };
    return (
      <div>
        <h3>{companyData.name}</h3>
        <TableWrapper>
          <Table
            columns={columns}
            dataSource={userDataSource()}
            pagination={{
              defaultPageSize: 5,
              total: userDataSource().length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </div>
    );
  };
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
    },
    {
      title: 'Total Agents',
      dataIndex: 'agents',
      key: 'agents',
    },
    {
      title: 'Active Agents',
      dataIndex: 'activeUsers',
      key: 'activeUsers',
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
    },
    {
      title: 'Price paid',
      dataIndex: 'price',
      key: 'price',
    },
  ];
  const dataSource = () => {
    return (company || []).map((obj: any, i: number) => {
      const { id, agents, name, phone, email, price, activeUsers, website, paymentDate } = obj;
      return {
        key: id,
        id: i + 1,
        agents,
        name,
        phone,
        activeUsers,
        email,
        price,
        website,
        paymentDate,
      };
    });
  };
  const onFinish = async (values: any) => {
    if (values.password === values.confirmPassword) {
      const result: any = dispatch(changePassword(modelData.id, values.password));
      if (result) {
        form.resetFields();
        setModelVisiable(!modelVisiable);
      }
    }
  };
  return (
    <>
      <TableWrapper>
        <Table
          expandable={{ expandedRowRender }}
          pagination={{
            defaultPageSize: 5,
            total: dataSource().length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          loading={!company}
          dataSource={dataSource()}
          columns={columns}
        />
      </TableWrapper>
      <Modal
        visible={modelVisiable}
        onOk={() => form.submit()}
        onCancel={() => setModelVisiable(!modelVisiable)}
        okText="Change"
      >
        <h3>Change Password</h3>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item label="Password" name={'password'} rules={validations.simpleValidation}>
            <Input.Password />
          </Form.Item>
          <Form.Item label="Confirm Password" name={'confirmPassword'} rules={validations.simpleValidation}>
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CompanyTable;
