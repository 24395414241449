import actions from './actions';
import reduxActionModal from '../../class/reduxActionModal';
import { questionTypes } from '../../helper/enum';
interface User {
  loading: boolean;
  data: object | null;
  types: string[];
  error: any;
}
const initialState: User = {
  loading: false,
  data: null,
  types: questionTypes,
  error: null,
};

const { USER_BEGIN, USER_SUCCESS, USER_ERR, LOGOUT_SUCCESS } = actions;

const Reducer = (state: User = initialState, action: reduxActionModal) => {
  const { type, data, err } = action;
  switch (type) {
    case USER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case USER_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Reducer;
