const actions = {
  QUESTION_BEGIN: 'QUESTION_BEGIN',
  QUESTION_SUCCESS: 'QUESTION_SUCCESS',
  DEMODATA_SUCCESS: 'DEMODATA_SUCCESS',
  QUESTION_ERR: 'QUESTION_ERR',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  questionBegin: () => {
    return {
      type: actions.QUESTION_BEGIN,
    };
  },

  questionSuccess: (data: any) => {
    return {
      type: actions.QUESTION_SUCCESS,
      data,
    };
  },
  demoSuccess: (data: any) => {
    return {
      type: actions.DEMODATA_SUCCESS,
      data,
    };
  },

  questionErr: (err: any) => {
    return {
      type: actions.QUESTION_ERR,
      err,
    };
  },
};

export default actions;
