import Styled from 'styled-components';
import { Table } from 'react-bootstrap';
const QuestionWrapper = Styled.div`
.nav-pills{
    padding:.5rem;
    // background:${(props) => props.theme.backgroundCard};
    box-shadow:0px 10px 30px 0px ${(props) => props.theme.primary}10;
    border-radius:10px;
    .nav-item{
        .nav-link{
            // color:${(props) => props.theme.dark};
            text-transform: capitalize;
            &.active{
                background-color:${(props) => props.theme.primary};
    box-shadow:0px 10px 30px 0px ${(props) => props.theme.black}10, inset 0px 0px 30px 0px ${(props) =>
  props.theme.black}10;
    color:${(props) => props.theme.dark};
                    }
        }
    }
}
.tab-content{
    padding:1.5rem;
    // background:${(props) => props.theme.backgroundCard};
    box-shadow:10px 10px 20px 0px ${(props) => props.theme.black}10;
            // color:${(props) => props.theme.dark};
            border-radius:10px;
}
`;
const TableWrapper = Styled(Table)`
tbody > tr:nth-of-type(odd){
    color:${(props) => props.theme.light};
    td{
        background:${(props) => props.theme.dark}90;
        border:none;
    }
}

thead>tr> th{
    border:none;
    background:${(props) => props.theme.primary};
    color:white;

}
td,th{
    padding:1rem;
}
td:first-child,th:first-child{
    border-top-left-radius:10px;
    border-bottom-left-radius:10px;
}
td:last-child,th:last-child{
    border-top-right-radius:10px;
    border-bottom-right-radius:10px;
}
`;
export { QuestionWrapper, TableWrapper };
