import actions from './actions';
import reduxActionModal from '../../class/reduxActionModal';
import { questionTypes } from '../../helper/enum';
interface Auth {
  loading: boolean;
  data: object | null;
  types: string[];
  error: any;
  demoData:any
}
const initialState: Auth = {
  loading: false,
  data: null,
  types: questionTypes,
  error: null,
  demoData:null
};

const { QUESTION_BEGIN, QUESTION_SUCCESS, QUESTION_ERR, LOGOUT_SUCCESS,DEMODATA_SUCCESS } = actions;

const Reducer = (state: Auth = initialState, action: reduxActionModal) => {
  const { type, data, err } = action;
  switch (type) {
    case QUESTION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DEMODATA_SUCCESS:
      return {
        ...state,
        demoData:data,
        loading: false,
      };
    case QUESTION_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case QUESTION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Reducer;
