import actions from './actions';

const { changeLayoutBegin, changeLayoutSuccess, changeLayoutErr, changeSideNavSuccess } = actions;

const changeLayoutMode = (value: boolean) => {
  return async (dispatch: any) => {
    try {
      dispatch(changeLayoutBegin());
      dispatch(changeLayoutSuccess(value));
    } catch (err) {
      dispatch(changeLayoutErr(err));
    }
  };
};
const changeSideNavMode = (value: boolean) => {
  return async (dispatch: any) => {
    dispatch(changeSideNavSuccess(value));
  };
};

export { changeLayoutMode, changeSideNavMode };
