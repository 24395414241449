import actions from './actions';
import API from '../../config/API';
import { getApi, postApi, putApi } from '../../helper/ApiRequest';
// import CRMModel from '../../class/CRMModal';

const { CRMBegin, CRMSuccess, CRMErr, NotesBegin, NotesSuccess, NotesErr } = actions;

const addCRM = (values: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(CRMBegin());
      const result: any = await postApi(`${API.CRM.root}`, values, dispatch);
      if (result.status === 200) {
        const response: any = await getApi(`${API.CRM.root}`, dispatch);
        if (response.status === 200) {
          dispatch(CRMSuccess(response.data));
          return true;
        }
      }
    } catch (err) {
      dispatch(CRMErr(err));
      return false;
    }
  };
};
const getCRM = () => {
  return async (dispatch: any) => {
    try {
      dispatch(CRMBegin());
      const response: any = await getApi(`${API.CRM.root}`, dispatch);
      if (response.status === 200) {
        dispatch(CRMSuccess(response.data));
      } else {
        throw response;
      }
    } catch (err) {
      dispatch(CRMErr(err));
    }
  };
};
const addNotes = (values: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(NotesBegin());
      const result: any = await postApi(`${API.CRM.notes}`, values, dispatch);
      if (result.status === 200) {
        return true;
      }
    } catch (err) {
      dispatch(NotesErr(err));
      return false;
    }
  };
};
const updateNotes = (values: any, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(NotesBegin());
      const result: any = await putApi(`${API.CRM.notes}/${id}`, values, dispatch);
      if (result.status === 200) {
        return true;
      }
    } catch (err) {
      dispatch(NotesErr(err));
      return false;
    }
  };
};
const getNotes = (id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(NotesBegin());
      const response: any = await getApi(`${API.CRM.notes}/${id}`, dispatch);
      if (response.status === 200) {
        dispatch(NotesSuccess(response.data));
      }
    } catch (err) {
      dispatch(NotesErr(err));
    }
  };
};

export { getCRM, addCRM, addNotes, getNotes, updateNotes };
