
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Siriwave from 'react-siriwave';
function MyWave() {
  const { theme } = useSelector((state: any) => {
    return {
      theme: state.ChangeLayoutMode.theme,
    };
  });
  const [waveAmp, setwaveAmp] = useState(6);
  const [waveSpeed, setWaveSpeed] = useState(0.3);
  const [wave, setWave] = useState<Element | null>(null);
  const stopMyWave = () => {
    setwaveAmp(0);
    setWaveSpeed(0);
  };
  const waveStart = () => {
    setwaveAmp(1);
    setWaveSpeed(0.2);
  };
  const countWave = () => {
    if (!wave) setWave(document.querySelector('#waveCont'));
  };
  useEffect(() => {
    if (!wave) {
      countWave();
    }
  });
  return (
    <div className="d-flex justify-content-center mt-2 overflow-hidden" style={{ height: '10%', width:'98%' }} id="waveCont">
      <button className="d-none btn btn-primary" onClick={stopMyWave}>
        Stop
      </button>
      <button className="d-none btn btn btn-primary" onClick={waveStart}>
        Start
      </button>
      {wave ? (
        <div className={!theme ? 'Siriwave-styleLight' : 'Siriwave-style'}>
          <Siriwave
            style={'ios9'}
            color={'#00bb1d'}
            amplitude={waveAmp}
            speed={waveSpeed}
            width={(wave as any)?.offsetWidth}
            // height={70}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default MyWave;
