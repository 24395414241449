const API = {
  auth: {
    login: '/auth/login',
    logout: '',
  },
  questions: {
    root: '/question',
    demo:"/demo"
  },
  Users: {
    root: '/user',
    changeStatus: '/user/status',
    changePassword: '/user/password',
  },
  Company: {
    root: '/company',
    register: '/company/register',
    paymentVerify: '/company/successPayment',
  },
  CRM: {
    root: '/client',
    notes: '/notes',
    newNotes: '/',
  },
  payment: {
    root: '/payment',
  },
};

export default API;
