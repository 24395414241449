import { useState, useEffect } from 'react';
import './smartphone.scss';
import { FaPhoneAlt } from 'react-icons/fa';
import { ImCross, ImHeadphones, ImPause2 } from 'react-icons/im';
import { BsBellSlashFill, BsPersonCircle, BsVolumeUpFill } from 'react-icons/bs';
import { MdCallEnd, MdDialpad, MdPersonAdd } from 'react-icons/md';
// import io from 'socket.io-client';
import { getApi } from '../../../../helper/ApiRequest';
import audioBufferToWav from 'audiobuffer-to-wav';

import logo from '../../../../assets/img/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillClockCircle, AiFillStar, AiOutlineClockCircle } from 'react-icons/ai';
import { IoMdContact } from 'react-icons/io';
import { IoPersonCircleSharp } from 'react-icons/io5';
function MobileApp() {
  const { theme } = useSelector((state) => {
    return {
      theme: state.ChangeLayoutMode.theme,
    };
  });
  // const { theme } = useSelector((state: any) => {
  //   return {
  //     theme: state.ChangeLayoutMode.theme,
  //   };
  // });
  let device;
  let token;
  let spkFileId = 0;
  let micFileId = 0;
  let speakerBlob = null;
  let micBlob = null;
  const [inputValue, setInputValue] = useState('');
  const [isCallActive, setIsCallActive] = useState(false);
  const [callerDevice, setCallerDevice] = useState(null);
  const [callInstance, setCallInstance] = useState(null);
  const [timer, setTimer] = useState(null);
  const [timerInterval, setTimerInterval] = useState(null);
  const convertBlobToWav = async (blob) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await blob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const wavData = audioBufferToWav(audioBuffer);
    return new Blob([wavData], { type: 'audio/wav' });
  };
  const downloadWav = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.href = url;
    // link.download = filename;
    // link.click();
  };
  useEffect(() => {
    getAudioDevices();
    startupClient();
    document.getElementById('caller-voice').addEventListener('change', function () {
      fetch(document.getElementById('caller-voice').value)
        .then((response) => response.blob())
        .then((blob) => (speakerBlob = convertBlobToWav(blob)))
        .then((wavBlob) => {
          // downloadWav(wavBlob, `spk${spkFileId}.wav`);
          // var formdata = new FormData();
          // formdata.append('file', wavBlob, 'sample-file-4.wav');

          // var requestOptions = {
          //   method: 'POST',
          //   body: formdata,
          //   redirect: 'follow',
          // };

          // fetch('https://18.130.255.54:3001/upload_spk', requestOptions)
          //   .then((response) => response.text())
          //   .then((result) => console.log(result))
          //   .catch((error) => console.log('error', error));
          const formData = new FormData();
          formData.append('file', wavBlob, `spk${spkFileId}.wav`);
          fetch(process.env.REACT_APP_AUDIO_UPLOAD_URL + '/upload_spk', {
            method: 'POST',
            body: formData,
            redirect: 'follow',
          })
            .then((response) => response.json())
            .then((data) => {})
            .catch((error) => console.error(error));
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      // socket.emit('callerFile', file);

      spkFileId++;
    });
    // socket.emit(`callerFile`, blob);

    document.getElementById('receiver-voice').addEventListener('change', function () {
      fetch(document.getElementById('receiver-voice').value)
        .then((response) => response.blob())
        .then((blob) => (micBlob = convertBlobToWav(blob)))
        .then((wavBlob) => {
          //downloadWav(wavBlob, `mic${micFileId}.wav`);
          const formdata = new FormData();
          formdata.append('file', wavBlob, `mic${micFileId}.wav`);
          fetch(process.env.REACT_APP_AUDIO_UPLOAD_URL + '/upload_mic', {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
          })
            .then((response) => response.json())
            .then((data) => {})
            .catch((error) => console.error(error));
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      // socket.emit('receiverFile', file);

      micFileId++;
    });
    // socket.emit('receiverFile', blob);
    document.getElementById('hangup-status').value = 0;
  }, []);

  const startupClient = async () => {
    console.log('Requesting Access Token...');

    try {
      const data = await getApi('/twi/token');
      token = data.data.token;
      intitializeDevice();
    } catch (err) {
      console.log(err);
      console.log('An error occurred. See your browser console for more information.');
    }
  };

  const intitializeDevice = () => {
    console.log('Initializing device', token);
    device = new Twilio.Device(token, {
      logLevel: 1,
      codecPreferences: ['opus', 'pcmu'],
    });

    addDeviceListeners(device);
    device.register();
  };

  const addDeviceListeners = (device) => {
    device.on('registered', function () {
      console.log('Twilio.Device Ready to make and receive calls!', device);
      setCallerDevice(device);
    });

    device.on('error', function (error) {
      console.log('Twilio.Device Error: ' + error.message);
    });
  };

  const makeOutgoingCall = async () => {
    var params = {
      To: '+' + inputValue,
    };

    if (callerDevice) {
      console.log(`Attempting to call ${params.To} ...`);
      const call = await callerDevice.connect({ params });
      setCallInstance(call);
      call.on('accept', updateUIAcceptedOutgoingCall);
      call.on('disconnect', updateUIDisconnectedOutgoingCall);
      call.on('cancel', updateUIDisconnectedOutgoingCall);
    } else {
      console.log('Unable to make call.');
    }
  };

  const updateUIDisconnectedOutgoingCall = () => {
    console.log('Call disconnected.');
    setIsCallActive(false);
    setInputValue('');
    stopTimer();
  };

  const endCall = () => {
    console.log('Hanging up ...');
    callInstance.disconnect();
    setIsCallActive(false);
    setInputValue('');
    stopTimer();
  };

  const updateUIAcceptedOutgoingCall = (call) => {
    console.log('Call in progress ...');
    document.getElementById('hangup-status').value = 1;
    setIsCallActive(true);
    startTimer();
    // bindVolumeIndicators(call);
  };

  const startTimer = () => {
    let startTime = 0;
    startTime = Date.now();

    setTimerInterval(
      setInterval(() => {
        let elapsedTime = Math.floor((Date.now() - startTime) / 1000);
        let hours = Math.floor(elapsedTime / 3600);
        let minutes = Math.floor((elapsedTime % 3600) / 60);
        let seconds = elapsedTime % 60;

        setTimer(
          `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`,
        );
      }, 1000),
    );
  };

  const stopTimer = () => {
    clearInterval(timerInterval);
    setTimer(null);
    setTimerInterval(null);
    document.getElementById('hangup-status').value = 0;
    spkFileId = 0;
    micFileId = 0;
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = (value) => {
    setInputValue(`${inputValue}${value}`);
  };

  const getAudioDevices = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true });
  };

  const dialerPadMainScreen = () => {
    return (
      <div className={!theme ? 'container-LightTextBox-phone ' : 'container-textBox-phone '}>
        <div className="layout-container paddingTopMobile">
          <div className={!theme ? 'phone-to-call-active' : 'phone-to-call'}>
            <h2>
              <input
                type="number"
                min={0}
                pattern="[0-9 ]+"
                autoFocus
                value={inputValue}
                onChange={handleInputChange}
              />
            </h2>
            <p className="">Affective AI</p>
          </div>
          <div className={!theme ? 'phone-dialpadlight' : 'phone-dialpad'}>
            <ul style={{ paddingLeft: 0 }}>
              <li value="1" onClick={() => handleButtonClick(1)}>
                1 <span>&nbsp;</span>
              </li>
              <li value="2" onClick={() => handleButtonClick(2)}>
                2 <span>abc</span>
              </li>
              <li value="3" onClick={() => handleButtonClick(3)}>
                3 <span>def</span>
              </li>
              <li value="4" onClick={() => handleButtonClick(4)}>
                4 <span>ghi</span>
              </li>
              <li value="5" onClick={() => handleButtonClick(5)}>
                5 <span>jkl</span>
              </li>
              <li value="6" onClick={() => handleButtonClick(6)}>
                6 <span>mno</span>
              </li>
              <li value="7" onClick={() => handleButtonClick(7)}>
                7 <span>pqrs</span>
              </li>
              <li value="8" onClick={() => handleButtonClick(8)}>
                8 <span>tuv</span>
              </li>
              <li value="9" onClick={() => handleButtonClick(9)}>
                9 <span>wxyz</span>
              </li>
              <li>
                * <span>P</span>
              </li>
              <li value="0" onClick={() => handleButtonClick(0)}>
                0 <span>+</span>
              </li>
              <li>
                # <span>W</span>
              </li>
              <li>
                <BsPersonCircle className="fa fa-user-circle-o mt-2" style={{ color: '#ffffff' }}></BsPersonCircle>
              </li>
              <li id="button-call" onClick={() => makeOutgoingCall()}>
                <FaPhoneAlt className="fa fa-phone mt-2" style={{ color: '#ffffff' }}></FaPhoneAlt>
              </li>
              <li onClick={() => (inputValue.length > 0 ? setInputValue(inputValue.slice(0, -1)) : null)}>
                <ImCross className="fa fa-times mt-2" style={{ color: '#ffffff' }}></ImCross>
              </li>
            </ul>
          </div>
          <div className="phone-bottom-menu">
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div>
                <div>
                  <AiFillStar
                    className={!theme ? 'fa fa-clock-o  fone-bottom-icon-light' : 'fa fa-clock-o  fone-bottom-icon'}
                  ></AiFillStar>
                  <div className={!theme ? 'fone-bottom-text-light' : 'fone-bottom-text'}>Favourites</div>
                </div>
              </div>
              <div>
                <AiFillClockCircle
                  className={!theme ? 'fa fa-user-plus fone-bottom-iconss-light' : 'fa fa-user-plus fone-bottom-iconss'}
                ></AiFillClockCircle>
                <div className={!theme ? 'fone-bottom-text-light' : 'fone-bottom-text'}>Recents</div>
              </div>
              <div>
                <IoPersonCircleSharp
                  className={!theme ? 'fa fa-clock-o fone-bottom-iconss-light' : 'fa fa-clock-o fone-bottom-iconss'}
                ></IoPersonCircleSharp>
                <div className={!theme ? 'fone-bottom-text-light' : 'fone-bottom-text'}>Contact</div>
              </div>
              <div>
                <MdDialpad
                  className={!theme ? 'fa fa-user-plus fone-bottom-iconss-light' : 'fa fa-user-plus fone-bottom-iconss'}
                ></MdDialpad>
                <div className={!theme ? 'fone-bottom-text-light' : 'fone-bottom-text'}>Keypad</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const dialerPad2ndScreen = () => {
    return (
      <div className={!theme ? 'container-LightTextBox ' : 'container-textBox '}>
        <div className="layout-container user-bg  paddingTopMobile">
          <div className="overlay">
            <div className={!theme ? 'calling-info-Light' : 'calling-info'}>
              <h2>Affective AI</h2>
              <p>{inputValue}</p>
              <center>
                <small>Calling</small>
                <div id="timer" classNameName="col agent-time" style={{ marginTop: '18px' }}>
                  {timer}
                </div>
              </center>
            </div>
            <div className="call-icons">
              <ul style={{ paddingLeft: 55 }}>
                <li className="user-buttons endDecline" onClick={() => endCall()}>
                  <span>
                    <MdCallEnd style={{ marginLeft: '-2px', color: '#ffffff' }} className="fa fa-phone"></MdCallEnd>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="page-wrap">
      {isCallActive ? dialerPad2ndScreen() : dialerPadMainScreen()}
      <input type="hidden" id="caller-voice" onChange={() => console.log('changed1')} />
      <input type="hidden" id="receiver-voice" onChange={() => console.log('changed2')} />
      <input type="hidden" id="hangup-status" />
    </div>
  );
}

export default MobileApp;
