import React, { FC } from 'react';
import ChatComp from './ChatComp';
import MyWave from './myWave';
import logo from '../../../../assets/img/logo.svg';
import { MyCard } from '../styled';
import chatModal from '../../../../class/chatModal';
import Face1 from '../../../../assets/img/face1.png';
import Face2 from '../../../../assets/img/face2.png';
import Folder from '../../../../assets/img/Folder.png';
import icon4 from '../../../../assets/img/4.png';
import icon9 from '../../../../assets/img/9.png';
import { Progress, Tooltip } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
const Index: FC<{ chat: chatModal[] }> = ({ chat }) => {
  console.log('chat', chat);
  const { theme } = useSelector((state: any) => {
    return {
      theme: state.ChangeLayoutMode.theme,
    };
  });
  return (
    <>
      <div className={!theme ? 'container-LightTextBox ' : 'container-textBox'} style={{ padding: '12px' }}>
        <div className="flex-clock-image-center">
          <div>
            <div className={!theme ? 'heading-LightChatBoxText' : 'heading-ChatBoxText'}>Jonathan Ortega</div>
            <div className={!theme ? 'heading-LightChatBoxText' : 'heading-ChatBoxText'}>Head of Sales</div>
          </div>
          <div className={!theme ? 'ImageContainer-MessagecenterLight' : 'ImageContainer-Messagecenter'}>
            <img src={logo} alt="logo" className={!theme ? 'heightWidth-light' : 'heightWidth-ImageText'} />
          </div>
          <div>
            <div className={!theme ? 'heading-LightChatBoxText' : 'heading-ChatBoxText'}>00:15:26</div>
            <div className={!theme ? 'heading-LightChatBoxText' : 'heading-ChatBoxText'}>Affective AI</div>
          </div>
        </div>
        <Tooltip title="3 done / 3 in progress / 4 to do">
          <Progress
            style={{ marginTop: '12px' }}
            percent={20}
            success={{
              percent: 5,
            }}
            showInfo={false}
          />
        </Tooltip>
        <div className={!theme ? 'messageParent-LightContainer' : 'messageParent-Container'}>
          <div>

        <div style={{ display: 'flex', justifyContent: 'end' }}>
            <div className="receiverMessage-container">
              <div className={!theme ? 'textReveiverMessage-Lightcontainer ' : 'textReveiverMessage-container'}>
                
              </div>
            </div>
            <div id="triangle-topleft"></div>
          </div>       
          <div style={{display:'flex', justifyContent:'center'}}>                                                                                                                                                                                                                                        
          <div className={!theme ? 'container-boxLight' : 'container-box'}>
            <img src={Folder} alt="logo" className="imageFolder-container " />
            <div className={!theme ? 'textSenderMessage-Darkcontainer' : 'textSenderMessage-Lightcontainer'} style={{marginRight:"43px"}}>
              Increase Enthusiasm
            </div>
          </div>
          </div>
          
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <div className="receiverMessage-container">
              <div className={!theme ? 'textReveiverMessage-Lightcontainer ' : 'textReveiverMessage-container'}>
                
              </div>
            </div>
            <div id="triangle-topleft"></div>
          </div>
          <div style={{ display: 'flex' }}>
            <div id="triangle-topright"></div>
            <div className="senderMessage-box">
              <img src={icon4} alt="logo" style={{height:'24px', width:'11px', marginLeft:'5px'}} />
              <div className={!theme ? 'textSenderMessage-containerLight' : 'textSenderMessage-container'} style={{fontSize:"13px",fontWeight:"600", color:"#000000"}}>
                Objection: "Im not interested"
              </div>
            </div>
          </div>
          <div style={{display:'flex', justifyContent:'center'}}> 
          <div className={!theme ? 'container-BigboxLight' : 'container-Bigbox'}>    
            <div className={!theme ? 'Text-bigContainer-2ndheadingLight' : 'Text-bigContainer-2ndheading'}>
              "Believe me. I'm with you. But the good news is that taking just 30 seconds with me right now could change the way you do business, and could help you achieve (list a benefit of weather your product or service will do for them). In fact, let me share briefly with you how we've helped hundreds of companies just like yours."
            </div>          
          </div>
          </div>

           <div style={{ display: 'flex', justifyContent: 'end' }}>
            <div className="receiverMessage-container">
              <div className={!theme ? 'textReveiverMessage-Lightcontainer ' : 'textReveiverMessage-container'}>
                
              </div>
            </div>
            <div id="triangle-topleft"></div>
          </div>
          <div style={{display:'flex', justifyContent:'center'}}> 
          <div className={!theme ? 'container-boxLight' : 'container-box'}>
            <img src={icon9} alt="logo" className="imaheheart-container" />
            <div className={!theme ? 'textSenderMessage-Darkcontainer' : 'textSenderMessage-Lightcontainer'} style={{marginRight:"43px"}}>
              Increase Empathy
            </div>
            </div>
          </div>
          
          <div style={{ display: 'flex' }}>
            <div id="triangle-topright"></div>
            <div className="senderMessage-box">
              <div className={!theme ? 'textSenderMessage-containerLight' : 'textSenderMessage-container'} style={{fontSize:"16px",fontWeight:"600", color:"#000000"}}>
              
              </div>
            </div>
          </div>
          <div style={{display:'flex', justifyContent:'center'}}> 
          <div className={!theme ? 'container-boxLight' : 'container-box'}>
            <div className={!theme ? 'textSenderMessage-Darkcontainer' : 'textSenderMessage-Lightcontainer'}>
              Well done objection handled
            </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <div className="receiverMessage-container">
              <div className={!theme ? 'textReveiverMessage-Lightcontainer ' : 'textReveiverMessage-container'}>
                
              </div>
            </div>
            <div id="triangle-topleft"></div>
          </div>
          <div style={{ display: 'flex' }}>
            <div id="triangle-topright"></div>
            <div className="senderMessage-box">
              <div className={!theme ? 'textSenderMessage-containerLight' : 'textSenderMessage-container'} style={{fontSize:"16px",fontWeight:"600", color:"#000000"}}>
                
              </div>
            </div>
          </div>
          
        
          </div>
        
        </div>
      </div>
    </>
  );
};

export default Index;
