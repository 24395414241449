import { Col, Form, Input, Row, InputNumber, Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { addCRM } from '../../../redux/CRM/actionCreator';
import { Main } from '../../styled';

const AddCRM = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = async (values: any) => {
    const response: any = await dispatch(addCRM(values));
    if (response) {
      form.resetFields();
    }
  };
  return (
    <Main>
      <h3>Add Client</h3>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[20, 0]}>
          <Col xs={24} sm={12}>
            <Form.Item label="First Name" name={'firstName'}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Last Name" name={'lastName'}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="Phone" name={'phone'}>
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item label="email" name={'email'}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Main>
  );
};

export default AddCRM;
