import React, { FC } from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SubMenu from 'antd/lib/menu/SubMenu';
import SideNavLinkGen from './SideNavLinkGen';
import { SideNavWrapper } from './styled';
import SideNavLink from './SideNavLink';
import { MenuData } from '../constant/MenuData';
import { MenuData as AdminMenu } from '../constant/AdminMenu';
import { MenuData as superAdminMenu } from '../constant/superAdminMenu';
import MenuDataModal from '../class/MenuDataModal';
import AppIcons from '../helper/AppIcons';
import 'antd/dist/antd.css';
import MyWave from '../pages/User/Liai/SectionTwo/myWave';
import { ContainerOutlined, PieChartOutlined, DesktopOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}
const SideNav: FC<{ role: string; menuId: number }> = ({ role, menuId }) => {
  const { theme, sideNavMode } = useSelector((state: any) => {
    return {
      theme: state.ChangeLayoutMode.theme,
      sideNavMode: state.ChangeLayoutMode.sideNavMode,
    };
  });

  const linkReturn = (type: string) => {
    switch (type) {
      case 'user':
        return MenuData.map((obj: MenuDataModal) => {
          if (obj.subMenu) {
            return (
              <SubMenu key={obj.id} icon={AppIcons(obj.icon)} title={<span className="ps-2">{obj.text}</span>}>
                <Menu.Item>
                  <NavLink to={obj.link} style={{textDecoration:"none"}}>All CRM</NavLink>
                </Menu.Item>
                <SideNavLink obj={obj} key={obj.id} />
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item icon={AppIcons(obj.icon)} key={obj.id}>
                <NavLink to={obj.link} style={{textDecoration:"none"}}>{obj.text}</NavLink>
              </Menu.Item>
            );
          }
        });
      case 'admin':
        return AdminMenu.map((obj, i) => {
          if (obj?.subMenu) {
            return <SideNavLinkGen key={obj.id} obj={obj} activeId={menuId} />;
          } else {
            return (
              <Menu.Item icon={AppIcons(obj.icon)} key={obj.id.toString()}>
                <NavLink to={obj.link} style={{textDecoration:"none"}}>{obj.text}</NavLink>
              </Menu.Item>
            );
          }
        });
      case 'superAdmin':
        return superAdminMenu.map((obj, i) => {
          if (obj?.subMenu) {
            return <SideNavLinkGen key={obj.id} obj={obj} activeId={menuId} />;
          } else {
            return (
              <Menu.Item icon={AppIcons(obj.icon)} key={obj.id.toString()}>
                <NavLink to={obj.link} style={{textDecoration:"none"}}>{obj.text}</NavLink>
              </Menu.Item>
            );
          }
        });
      default:
        break;
    }
  };
  return (
    <SideNavWrapper>
      <div className="sidebar-Menu h-100" style={{ width: sideNavMode ? 80 : 256, border:'1px solid #959697', borderRadius:'10px',paddingLeft:'4px', paddingTop:'4px', marginTop:'2px'}}>

         <Menu
          className={!theme ? 'sidebarMenu' : 'height-sidebarMenu'}
          style={
            !theme
              ? {
                  borderRight: 'none',
                }
              : {
                  borderRight: '1px solid #0f60e2',
                }
          }
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          inlineCollapsed={sideNavMode}
        >
          {linkReturn(role)}
        </Menu>
        <div style={{ marginTop: '-19px' }}>
          <MyWave />
        </div>
      </div>

    </SideNavWrapper>
  );
};

export default SideNav;
