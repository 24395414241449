import actions from './actions';
import reduxActionModal from '../../class/reduxActionModal';
import { questionTypes } from '../../helper/enum';
interface User {
  loading: boolean;
  data: object | null | undefined;
  notes: object | null;
  notesLoading: boolean;
  types: string[];
  error: any;
}
const initialState: User = {
  loading: false,
  data: null,
  notes: null,
  notesLoading: false,
  types: questionTypes,
  error: null,
};

const { CRM_BEGIN, CRM_SUCCESS, CRM_ERR, LOGOUT_SUCCESS, NOTES_BEGIN, NOTES_SUCCESS, NOTES_ERR } = actions;

const Reducer = (state: User = initialState, action: reduxActionModal) => {
  const { type, data, err } = action;
  switch (type) {
    case NOTES_BEGIN:
      return {
        ...state,
        notesLoading: true,
      };
    case NOTES_SUCCESS:
      return {
        ...state,
        notes: data,
        notesLoading: false,
      };
    case NOTES_ERR:
      return {
        ...state,
        error: err,
        notesLoading: false,
      };
    case CRM_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CRM_SUCCESS:
      return {
        ...state,
        data,
        loading: false,
      };
    case CRM_ERR:
      return {
        ...state,
        error: err,
        data: undefined,
        loading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default Reducer;
