// import React from 'react';
// import SecCard from './SecCard';
// import Face1 from '../../../../assets/img/face1.png';
// import Face2 from '../../../../assets/img/face2.png';
// import Face3 from '../../../../assets/img/face3.png';
// import logo from '../../../../assets/img/logo.svg';
// import { MyCard } from '../styled';
// import SectionOneModal from '../../../../class/SectionOneModal';
// function SectionOne() {
//   const data: SectionOneModal[] = [
//     {
//       id: 1,
//       img: Face1,
//     },
//     {
//       id: 2,
//       img: Face2,
//     },
//     {
//       id: 3,
//       img: Face3,
//     },
//   ];
//   return (
//     <MyCard>
//       <div className="fw-bold  py-3 text-center">
//       <img src={logo} alt="logo" className="liailogo" />
//       </div>
//       <div className="rounded">
//         <SecCard title="Morning" data={data} />
//         <SecCard title="Afternoon" data={data} />
//         <SecCard title="Evening" data={data} />
//       </div>
//     </MyCard>
//   );
// }

// export default SectionOne;
import React from 'react';
import Face1 from '../../../../assets/img/face1.png';
import Face2 from '../../../../assets/img/face2.png';
import Face3 from '../../../../assets/img/face3.png';
import logo from '../../../../assets/img/logo.svg';
import Layer from '../../../../assets/img/Layer.png';
import Folder from '../../../../assets/img/Folder.png';
import icon1 from '../../../../assets/img/1.png';
import icon2 from '../../../../assets/img/2.png';
import icon3 from '../../../../assets/img/3.png';
import icon4 from '../../../../assets/img/4.png';
import icon5 from '../../../../assets/img/5.png';
import icon6 from '../../../../assets/img/6.png';
import icon7 from '../../../../assets/img/7.png';
import icon8 from '../../../../assets/img/8.png';
import icon9 from '../../../../assets/img/9.png';
import icon10 from '../../../../assets/img/10.png';

import { useDispatch, useSelector } from 'react-redux';
const arrText = [
  {
    text1: 'Morning',
    text2: <div>
      <div>Increase Enthusiasm</div>
      <div>Increase Empathy</div>
      <div>Stop Interupting</div>
    </div>,
    // text2: 'Increase Enthusias',
    image:  <img src={Folder} style={{height:"31px", width:"18px"}}/>,
    image1:  <img src={icon9} style={{height:"31px", width:"31px"}}/>,
    image2:  <img src={icon1} style={{height:"31px", width:"31px"}}/>,
  },
  {
    text1: 'Afternoon',
    text2: <div>Increase Empathy</div>,
    image:  <img src={icon8} style={{height:"31px", width:"31px"}}/>,
    image1:  <img src={icon1} style={{height:"31px", width:"31px"}}/>,
    image2:  <img src={icon7} style={{height:"31px", width:"31px"}}/>,
  },
  {
    text1: 'Evening',
    text2: <div>
    <div>Increase Enthusiasm</div>
    <div>Stop Interupting</div>
  </div>,
    image:  <img src={Folder} style={{height:"31px", width:"18px"}}/>,
    image1:  <img src={icon1} style={{height:"31px", width:"31px"}}/>,
    image2:  <img src={Layer} style={{height:"33px", width:"34px"}}/>,
  },
];
const SectionOne = () => {
  const { theme } = useSelector((state: any) => {
    return {
      theme: state.ChangeLayoutMode.theme,
    };
  });
  return (
    <div className={!theme ? 'container-Lightdevelopment-TextBox' : 'container-textBox-development'}>
      <div className={!theme ? 'Container-HeaderLightText' : 'Container-HeaderText'}>Improvement Targets for the day</div>
      <div className="ContainerCenter">
        <div className={!theme ? 'container-headerLightBox' : 'container-headerBox'}>
          {arrText.map((item) => {
            return (
              <div className="ContainerCenter">
                <div className={!theme ? 'container-LightTextBoxNumber' : 'container-TextBoxNumber'}>
                  <div className="flex-containerLeft">
                    <p className={!theme ? 'heading-LightBoxText' : 'heading-BoxText'}>{item?.text1}</p>
                    <div className="flex-center-image">
                      <div className={!theme ? 'ImageContainerLight' : 'ImageContainer'} >
                       {item?.image}
                      </div>
                      <div className={!theme ? 'ImageContainerLight' : 'ImageContainer'} >
                        {item?.image1}
                        </div>
                      <div className={!theme ? 'ImageContainerLight' : 'ImageContainer'} >
                        {item?.image2}
                        </div>
                    </div>
                  </div>
                  <div className={!theme ? 'boxContainer-bottomLight' : 'boxContainer-bottom'}>
                    <div className={!theme ? 'headingBottom-textBoxLight' : 'headingBottom-textBox'}>{item?.text2}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default SectionOne;
