import { Button, Col, Form, Input, Row } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import UserModel from '../../../class/userModal';
import { validations } from '../../../helper/validations';
import { addUsers } from '../../../redux/Users/actionCreator';
import { Main } from '../../styled';

const AddUser = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = async (values: UserModel) => {
    const result: any = await dispatch(addUsers(values));
    if (result) {
      form.resetFields();
    }
  };
  return (
    <Main>
      <h3>Add new User</h3>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={20}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="firstName" label="First Name" rules={validations.simpleValidation}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="lastName" label="Last Name" rules={validations.simpleValidation}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="email" label="Email" rules={validations.simpleValidation}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="password" label="Password" rules={validations.simpleValidation}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Main>
  );
};

export default AddUser;
