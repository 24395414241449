import { Col, Row } from 'antd';
import React, { FC } from 'react';
interface dataInter {
  id: number;
  title: string;
  img: string;
  pdf: string;
}
const PdfComp: FC<{ data: dataInter }> = ({ data }) => {
  return (
    <Row gutter={20} className="my-2">
      <Col xs={24} sm={12} md={8} lg={8} xl={8}>
        <img src={`./assets/blogs/${data.img}`} alt="mainImg" className="w-100" />
      </Col>
      <Col xs={24} sm={12} md={16} lg={16} xl={16}>
        <p>{data.title.slice(0,70)}</p>
        <a href="#" className="btn btn-primary">
          Download
        </a>
      </Col>
    </Row>
  );
};

export default PdfComp;
