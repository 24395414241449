import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
const PrivateRoute: FC<{ Component: any; path?: string; exact?: boolean; role: string }> = ({
  Component,
  role,
  path,
  ...rest
}) => {
  const { isLogin } = useSelector((state: any) => {
    return {
      isLogin: state.auth.data,
    };
  });
  const location = useLocation();
  // console.log('islogin', isLogin);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
    
      path={path}
      render={(props) => (isLogin && isLogin?.data?.type === role ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
