const actions = {
  CRM_BEGIN: 'CRM_BEGIN',
  CRM_SUCCESS: 'CRM_SUCCESS',
  CRM_ERR: 'CRM_ERR',

  NOTES_BEGIN: 'NOTES_BEGIN',
  NOTES_SUCCESS: 'NOTES_SUCCESS',
  NOTES_ERR: 'NOTES_ERR',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  NotesBegin: () => {
    return {
      type: actions.NOTES_BEGIN,
    };
  },

  NotesSuccess: (data: any) => {
    return {
      type: actions.NOTES_SUCCESS,
      data,
    };
  },

  NotesErr: (err: any) => {
    return {
      type: actions.NOTES_ERR,
      err,
    };
  },
  CRMBegin: () => {
    return {
      type: actions.CRM_BEGIN,
    };
  },

  CRMSuccess: (data: any) => {
    return {
      type: actions.CRM_SUCCESS,
      data,
    };
  },

  CRMErr: (err: any) => {
    return {
      type: actions.CRM_ERR,
      err,
    };
  },
};

export default actions;
