import { Button, Col, Form, Input, Row, InputNumber } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import companyModal from '../../../../class/companyModal';
import { validations } from '../../../../helper/validations';
import { addCompany } from '../../../../redux/Company/actionCreator';
import { Main } from '../../../styled';

const AddCompany = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = async (values: companyModal) => {
    const result: any = await dispatch(addCompany(values));
    if (result) {
      form.resetFields();
    }
  };
  // id?: number;
  // name: string;
  // phone: string;
  // agents: string;
  // price: number;
  // paymentRef: string;
  // email?: string;
  // website?: string;
  // comment?: string;
  return (
    <Main>
      <h3>Add new Compony</h3>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={20}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="name" label="Name" rules={validations.simpleValidation}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="phone" label="Phone" rules={validations.simpleValidation}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="agents" label="Number of Agents" rules={validations.simpleValidation}>
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="price" label="Price" rules={validations.simpleValidation}>
              <InputNumber />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="paymentRef" label="Payment Reference" rules={validations.simpleValidation}>
              <Input placeholder="referance" value="Random value" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="website" label="Website" rules={validations.simpleValidation}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="email" label="Email" rules={validations.simpleValidation}>
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="comment" label="Comment" rules={validations.simpleValidation}>
              <Input placeholder="referance" value="Random value" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Main>
  );
};

export default AddCompany;
