import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import TopNav from '../../components/TopNav';
import { postApi } from '../../helper/ApiRequest';
import PdfComp from './PdfComp';
import countryList from '../../assets/CountriesList.json';
const AddDemo = () => {
  const dispatch = useDispatch();
  const [showDownload, setshowDownload] = useState(false);
  const { Option } = Select;
  const countryListNew: any = countryList;

  const onFinish = async (values: any) => {
    try {
      const response: any = await postApi(
        '/demo',
        { ...values, purpose: window.location.pathname === '/download-pdf' ? 'download' : 'demo' },
        dispatch,
      );
      if (response.status === 200) {
        message.success('added successfull');
        setshowDownload(true);
      }
    } catch (error) {
      message.error('You have an error');
    }
  };
  const pdfData = [
    {
      id: Math.random(),
      img: 'The future of AI in the Field of Speech Recognition.jpg',
      title: 'AI in the Field of Speech Recognition',
      pdf: 'AI in the Field of Speech Recognition.pdf',
    },
    {
      id: Math.random(),
      img: 'the impact that ai has on the telecommunication industry.jpg',
      title: 'The impact that artificial intelligence has on the telecommunication industry',
      pdf: 'The impact that artificial intelligence has on the telecommunication industry.pdf',
    },
    {
      id: Math.random(),
      img: 'the use of ai in the real estate and housing market.jpg',
      title: 'The use of artificial intelligence in the real estate and housing market',
      pdf: 'The use of artificial intelligence in the real estate and housing market  .pdf',
    },
    {
      id: Math.random(),
      img: 'the advantages in ai techonology.jpg',
      title: 'The advancements in Artificial Intelligence technology',
      pdf: 'The advancements in Artificial Intelligence technology.pdf',
    },
    {
      id: Math.random(),
      img: 'revolutionizing CRM and sales through Ai in speech recognition in financial services.jpg',
      title:
        'Revolutionizing Customer Relationship Management and Sales through AI in Speech Recognition in Financial Services',
      pdf: 'Revolutionizing Customer Relationship Management and Sales through AI in Speech Recognition in Financial Services.pdf',
    },
    {
      id: Math.random(),
      img: 'How AI will revolutionising the healthcare sector.jpg',
      title: 'How AI is revolutionising the healthcare sector',
      pdf: 'How AI is revolutionising the healthcare sector.pdf',
    },
    {
      id: Math.random(),
      img: 'how ai is revolutionising the insurance sector.jpg',
      title: 'How AI is revolutionising the insurance sector',
      pdf: 'How AI is revolutionising the insurance sector .pdf',
    },
    {
      id: Math.random(),
      img: 'how ai in speech recognition is improving customer relations.jpg',
      title: 'How AI in Speech Recognition is Improving Customer Relations',
      pdf: 'How AI in Speech Recognition is Improving Customer Relations.pdf',
    },
    {
      id: Math.random(),
      img: 'how ai has affected the travel and hospitality industry.jpg',
      title: 'How artificial intelligence has affected the travel and hospitality industry',
      pdf: 'How artificial intelligence has affected the travel and hospitality industry.pdf',
    },
    {
      id: Math.random(),
      img: 'how ai changing the retail sector.jpg',
      title: 'How artificial intelligence is changing the retail sector',
      pdf: 'How artificial intelligence is changing the retail sector.pdf',
    },
    {
      id: Math.random(),
      img: 'Everything about AI in field of speech Recognition.jpg',
      title: 'Everything About AI in the Field of Speech Recognition',
      pdf: 'Everything About AI in the Field of Speech Recognition.pdf',
    },
  ];
  return (
    <div>
      <TopNav dark />
      <div className="pt-5 mt-5">
        <div className="container">
          <Row>
            <Col xs={0} sm={0} md={12} lg={16}>
              {window.location.pathname === '/demo' ? (
                <div>
                  <h3>Please enter details for demo</h3>
                </div>
              ) : (
                <div>
                  <h3>Please Enter Details for download files</h3>
                  {showDownload ? (
                    <Row>
                      {pdfData.map((data) => (
                        <Col key={data.id} xs={24} sm={24} md={12} lg={8}>
                          <PdfComp data={data} />
                        </Col>
                      ))}
                    </Row>
                  ) : null}
                </div>
              )}
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Form layout="vertical" className="bg-white p-3 mt-4 rounded" onFinish={onFinish}>
                <Row gutter={20}>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label="First Name" name={'firstName'} required>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label="Last Name" name={'lastName'} required>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label="Email" name={'email'} required>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label="Company Name" name={'companyName'} required>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Form.Item label="Job Title" name={'jobTitle'} required>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label="Phone" name={'phone'} required>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label="Employees" name={'employees'} required>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Form.Item label="Country" name={'country'} required>
                      <Select allowClear>
                        {countryListNew.map((obj: any) => (
                          <Option key={obj.name}>{obj.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddDemo;
