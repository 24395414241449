import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { postApi } from '../../helper/ApiRequest';
import axios from '../../helper/axios';
import { login } from '../../redux/Auth/actionCreator';
const Index: FC = () => {
  const dispatch = useDispatch();
  const submitFunction = async (e: any) => {
    e.preventDefault();
    // const url = 'http://localhost:8000/auth/login';
    // const data = { email: e.target.email.value, password: e.target.password.value };
    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //   },
    // };

    // const reso =await postApi(url, data, dispatch)
    dispatch(login({ email: e.target.email.value, password: e.target.password.value }));
  };

  return (

    <div className="loginPage">

      <div className="login-box">

      <form onSubmit={submitFunction} autoComplete="off"  className="loginForm">
  <div className="content text-light px-2 py-3 px-md-3 py-md-4">
    <h3 className="text-center mb-5">Welcome Back</h3>

    <div className="form-group">
      <label htmlFor="email">Email</label>
      <div className="form__group field">
        <input
        type='email'
          required
          name="email"
          placeholder="Email Address"
          className="form__field"
          autoComplete='off'
          
        />
      </div>
    </div>

    <div className="form-group mb-3 mt-3">
      <label htmlFor="password">Password</label>
      <div className="form__group field">
        <input
          type="password"
          required
          name="password"
          className="form__field"
          placeholder="Password"
          autoComplete="new-password"
          
        />
      </div>
    </div>

    <div className="form-group mb-3 mt-5">
      <button type="submit" className="btn btn-light f-20 py-2 w-100">
        Login
      </button>
    </div>
  </div>
</form>
      </div>
    </div>
  );
};

export default Index;
