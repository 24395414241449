import { useEffect, useMemo, useRef, useState } from 'react';
// import { Button, Col, Form, Row, Select } from 'antd';
// import { useSelector } from 'react-redux';

import { useDispatch, useSelector } from 'react-redux';
// import Peer, { SignalData } from 'simple-peer';
import io from 'socket.io-client';
// import opusscript from 'opusscript';
// import toArray from 'stream-to-array';
// import { ReadableStream } from 'web-streams-polyfill';
// import SectionOne from './SectionOne';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import chatModal from '../../../class/chatModal';
import { SocketClientEvent } from '../../../helper/enum';
import MobileApp from './MobileApp';
import SectionOne from './SectionOne';
// import { Stream } from 'stream';
interface Caller {
  userToCall: string;
  signal: any;
  from: string;
  name: string;
}
// interface anserInt {
//   signal: any;
//   to: string;
// }
// console.log('request connection');
// const socket = io('10.192.30.154:9092', {
//   withCredentials: true,
//   extraHeaders: {
//     'my-custom-header': 'abcd',
//   },

// const socket = io('10.192.30.154:9092', {
//   withCredentials: true,
//   extraHeaders: {
//     'my-custom-header': 'abcd',
//   },
// });

// const socket = io('http://localhost:8000');
function Home() {
  const { isLogin } = useSelector((state: any) => ({
    isLogin: state.auth.data,
  }));
  // const [recorder, setrecorder] = useState<any>(null);
  const [recorder, setrecorder] = useState<MediaRecorder | null>(null);
  const [recorder2, setrecorder2] = useState<MediaRecorder | null>(null);

  const [chat, setChat] = useState<chatModal[]>([
    {
      id: Math.random(),
      type: 'chatRight',
      sentence: 'some random text',
    },
    {
      id: Math.random(),
      type: 'chatCenter',
      sentence: 'some random text',
    },
    {
      id: Math.random(),
      type: 'textArea',
      sentence: 'some random text',
    },
    {
      id: Math.random(),
      type: 'chatLeft',
      sentence: 'some random text',
    },
    {
      id: Math.random(),
      type: 'chatCenter',
      sentence: 'some random text',
    },
  ]);
  const [audioUrl, setAudioUrl] = useState<string>('');
  const fromVoiceRef = useRef<any>();
  // const audioRef = useRef<any>();
  // const [socketConnect, setsocketConnect] = useState(false);
  // const audioContext = useRef<AudioContext | null>(null);
  // const sourceNode = useRef<MediaStreamAudioSourceNode | null>(null);
  // const destinationNode = useRef<MediaStreamAudioDestinationNode | null>(null);
  // const mediaRecorder = useRef<MediaRecorder | null>(null);
  const socket = useRef(io('https://portal.affectiveai.com'));
  console.log('navigator.mediaDevices', navigator.mediaDevices);

  useEffect(() => {
    // navigator.mediaDevices
    //   .enumerateDevices()
    //   .then((gotDevices) => {
    //     const gotAudioInputDevice = gotDevices?.filter(
    //       (obj) => obj.kind === 'audioinput' && obj.deviceId !== 'default',
    //     );
    //     const gotAudioOutputDevice = gotDevices?.filter(
    //       (obj) => obj.kind === 'audiooutput' && obj.deviceId !== 'default',
    //     );
    //     console.log(gotAudioInputDevice);

    //     navigator.mediaDevices
    //       .getUserMedia({
    //         audio: { deviceId: { exact: gotAudioOutputDevice[0].deviceId } },
    //         video: false,
    //       })
    //       .then((stream) => {
    //         // create a new audio context and audio nodes when the component mounts
    //         const record = new MediaRecorder(stream);
    //         setrecorder(record);
    //         record.ondataavailable = (e) => {
    //           const blob = new Blob([e.data], {
    //             type: 'audio/mp4; codecs=opus',
    //           });
    //           const url = URL.createObjectURL(blob);
    //           const audio = new Audio(url);
    //           console.log(audio);

    //           socket.current.emit('audioData', { data: blob });
    //           setAudioUrl(url);
    //         };
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //     navigator.mediaDevices
    //       .getUserMedia({
    //         audio: { deviceId: { exact: gotAudioInputDevice[0].deviceId } },
    //         video: false,
    //       })
    //       .then((stream) => {
    //         // create a new audio context and audio nodes when the component mounts
    //         const record = new MediaRecorder(stream);
    //         setrecorder2(record);
    //         record.ondataavailable = (e) => {
    //           const blob = new Blob([e.data], {
    //             type: 'audio/mp4; codecs=opus',
    //           });
    //           socket.current.emit('audioData', { data: blob });
    //           const url = URL.createObjectURL(blob);
    //           setAudioUrl(url);
    //         };
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //       });
    //   })
    //   .catch((errorCallback) => console.log(errorCallback));

    // create a new socket.io client and listen for the 'audio' event
    socket.current = io('ws://portal.affectiveai.com');
    socket.current.on('audio', (data) => {
      console.log(data);

      // do something with the audio data received from the server
    });
  }, []);

  useEffect(() => {
    socket.current.on('connect', () => {
      console.log('connected');
      // setsocketConnect(true);
      socket.current.emit(SocketClientEvent.USER_CONNECTED, isLogin?.data?.id);
    });
  }, []);
  console.log(recorder?.state === 'inactive');
  const dispatch = useDispatch();
  const { theme } = useSelector((state: any) => {
    return {
      theme: state.ChangeLayoutMode.theme,
    };
  });
  return (
    <div className="container-fluid mt-0 mt-lg-3">
    <div className="row" style={{marginTop:'-7px'}}>
      <div className="col-12 col-md-12 col-xl-12">
        <div className={!theme ? 'container-Api-LightText' : 'container-Api-text'}>When you want to breathe as bad as you want to succeed</div>
      </div>
    </div>
    <div className="row mt-5 mb-5">
      <div className="col-12 col-md-12 col-lg-6  col-xl-4 ">
        <SectionOne />
      </div>
      <div className="col-12 col-md-12 col-lg-6  col-xl-4 ">
        <SectionTwo chat={chat} />
      </div>
      <div className="col-12 col-md-12 col-lg-6 col-xl-4 ">
        <MobileApp />
      </div>
    </div>
  </div>
  );
}

export default Home;
